<template>
    <div class="row mt-3">
        <div class="col-6">
            <a :href="'#/giveaway/'+$route.params.id" class="btn btn-sm btn-light"><i class="fa fa-chevron-left"></i> {{ $t('backTo') }}</a>
            <h3>{{ $t('uploadGiveawayCodes') }}</h3>
        </div>
        <div class="col-12">
            <hr/>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6">
            <div class="row">
                <div class="col-12 mb-3">
                    <span class="badge bg-secondary">{{ $t('giveawayCodesHelpText') }}</span>
                </div>
                <div class="col-12 mb-3">
                    <input type="file" @change="handleFileChange">
                    <button @click="importGiveawayCodes" :disabled="!selectedFile" class="btn btn-sm btn-success">{{ $t('uploadExcelFile') }}</button>
                </div>
            </div>
            
        </div>
        <div class="col-12">
            <hr/>
        </div>
        <div class="col-12">
            <table class="table table-hover" v-if="codeData.length>0">
                <thead>
                    <tr>
                        <th scope="col">{{$t('giveawayCode')}}</th>
                        <th scope="col">{{$t('createdDate')}}</th>
                        <th scope="col">{{$t('isUsed')}}</th>
                        <th scope="col" style="width: 180px;">{{$t('delete')}}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(p,index) in codeData" :key="index">
                        <td>{{ p.id }}</td>
                        <td>{{ formatDateTurkish(p.createdDate) }}</td>
                        <td>{{ p.isUsed ? $t('used') : '-' }}</td>
                        <td><button class="btn btn-sm btn-danger" style="margin-left:10px;" @click="deleteCode(p.id)" :disabled="p.isUsed?true:false"><i class="fa fa-remove"></i> {{$t('delete')}}</button></td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>
<script>

import { collection, doc, getDoc, addDoc, setDoc, updateDoc, serverTimestamp, deleteDoc, query, where, getDocs, orderBy  } from "firebase/firestore";
import { getFirestore } from "firebase/firestore";
import db from '../firebase/init';
import { getFunctions, httpsCallable } from "firebase/functions";
import Swal from 'sweetalert2';
//import CustomDatatable from '@/components/CustomDatatable.vue';
import ExcelJS from 'exceljs';

export default {
    components: {
        //CustomDatatable
    },
    data(){
        return {
            formData: {
            },
            codeData:[],
            selectedFile: null
        }
    },
    async mounted() {
        await this.getGiveawayCodes();
    },  
    methods: {
        handleFileChange(event) {
            this.selectedFile = event.target.files[0];
        },
        async getGiveawayCodes(){

            const giveawayDocRef = doc(getFirestore(), 'giveaways', this.$route.params.id);
            const querySnap = await getDocs(query(collection(giveawayDocRef, 'codes'), orderBy('createdDate', 'desc')));
            this.codeData = querySnap.docs.map((doc) => {
                return {
                    id: doc.id,
                    createdDate: doc.data().createdDate,
                    isUsed: doc.data().isUsed,
                };
            });

        },
        async importGiveawayCodes(){
            if(this.selectedFile!=null){

                const reader = new FileReader();

                reader.onload = (e) => {
                    this.$setLoading(true);
                    let nowDate = serverTimestamp();

                    const data = new Uint8Array(e.target.result);

                    const workbook = new ExcelJS.Workbook();
                    workbook.xlsx.load(data).then(async () => {
                        const worksheet = workbook.getWorksheet(1);
                        const giveawayDocRef = doc(getFirestore(), 'giveaways', this.$route.params.id);
                        const codesCollectionRef = collection(giveawayDocRef, 'codes');
                        let rowCount = 0;
                        
                        worksheet.eachRow({ includeEmpty: false, stopAtFirstEmpty: true }, async (row) => {
                            rowCount++;
                            const cell = row.getCell('A');
                            const cellValue = cell.value;
                            if(cellValue!=null && cellValue!="" && cellValue!=undefined){

                                try{
                                    let code = {
                                        code: cellValue,
                                        createdDate: nowDate,
                                        isUsed: false,
                                        userId: null,
                                        displayName: null,
                                        avatar: null,
                                        usedDate: null,
                                        phone: null,
                                        city: null,
                                        district: null,
                                        address: null
                                    }
                                    await setDoc(doc(codesCollectionRef, code.code), code);
                                }catch (error) {
                                    console.log(error);
                                }
                                
                            }
                        });

                        await this.getGiveawayCodes();

                        Swal.fire({
                            title: this.$t('success'),
                            text: this.$t('saveSuccessful'),
                            icon: 'success',
                            confirmButtonText: this.$t('ok'),
                            confirmButtonColor: '#0f7438',
                        });

                        this.$setLoading(false);
                        
                        
                        
                    });
                };


                reader.readAsArrayBuffer(this.selectedFile);
                this.selectedFile = null;
            }
        },
        async deleteCode(_id){
            Swal.fire({
                title: this.$t('areYouSure'),
                text: this.$t('areYouSureDelete'),
                icon: 'question',
                showCancelButton: true,
                confirmButtonColor: '#0f7438',
                cancelButtonColor: '#6c757d',
                confirmButtonText: this.$t('delete'),
                cancelButtonText: this.$t('cancel')
            }).then(async (result) => {
                if (result.isConfirmed) {

                    const giveawayDocRef = doc(getFirestore(), 'giveaways', this.$route.params.id);
                    const docToDeleteRef = doc(collection(giveawayDocRef, 'codes'), _id);
                    try {
                        await deleteDoc(docToDeleteRef);                       
                        Swal.fire({
                            title: this.$t('success'),
                            text: this.$t('deleteSuccessful'),
                            icon: 'success',
                            confirmButtonText: this.$t('ok'),
                            confirmButtonColor: '#0f7438',
                        });
                        this.items = [];
                        await this.getGiveawayCodes();
                    } catch (error) {
                        Swal.fire({
                            title: this.$t('error'),
                            text: error,
                            icon: 'error',
                            confirmButtonText: this.$t('ok')
                        });
                    }
                }
            });
        },
        formatDateTurkish(timestamp) {
            if(timestamp!=null){
                const date = timestamp.toDate(); // Firestore Timestamp nesnesini JavaScript Date nesnesine dönüştürün

                const options = { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric' };
                const turkishLocale = 'tr-TR';

                return date.toLocaleDateString(turkishLocale, options);
            }else{
                const currentDate = new Date();
                const options = { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric' };
                const turkishLocale = 'tr-TR';

                return currentDate.toLocaleDateString(turkishLocale, options);
            }
            
        }
    },

}
</script>

