export default {
  "emptyTable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tabloda herhangi bir veri mevcut değil"])},
  "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["_TOTAL_ kayıttan _START_ - _END_ arasındaki kayıtlar gösteriliyor"])},
  "infoEmpty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kayıt yok"])},
  "infoFiltered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(_MAX_ kayıt içerisinden bulunan)"])},
  "infoThousands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["."])},
  "lengthMenu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sayfada _MENU_ kayıt göster"])},
  "loadingRecords": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yükleniyor..."])},
  "processing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İşleniyor..."])},
  "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ara:"])},
  "zeroRecords": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eşleşen kayıt bulunamadı"])},
  "paginate": {
    "first": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İlk"])},
    "last": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Son"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sonraki"])},
    "previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Önceki"])}
  },
  "aria": {
    "sortAscending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([": artan sütun sıralamasını aktifleştir"])},
    "sortDescending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([": azalan sütun sıralamasını aktifleştir"])}
  },
  "select": {
    "rows": {
      "_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["%d kayıt seçildi"])},
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 kayıt seçildi"])}
    },
    "cells": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 hücre seçildi"])},
      "_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["%d hücre seçildi"])}
    },
    "columns": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 sütun seçildi"])},
      "_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["%d sütun seçildi"])}
    }
  },
  "autoFill": {
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İptal"])},
    "fillHorizontal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hücreleri yatay olarak doldur"])},
    "fillVertical": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hücreleri dikey olarak doldur"])},
    "fill": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bütün hücreleri <i>%d</i> ile doldur"])}
  },
  "buttons": {
    "collection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koleksiyon <span class=\"ui-button-icon-primary ui-icon ui-icon-triangle-1-s\"></span>"])},
    "colvis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sütun görünürlüğü"])},
    "colvisRestore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görünürlüğü eski haline getir"])},
    "copySuccess": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 satır panoya kopyalandı"])},
      "_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["%ds satır panoya kopyalandı"])}
    },
    "copyTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Panoya kopyala"])},
    "csv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSV"])},
    "excel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excel"])},
    "pageLength": {
      "-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bütün satırları göster"])},
      "_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["%d satır göster"])}
    },
    "pdf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PDF"])},
    "print": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yazdır"])},
    "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kopyala"])},
    "copyKeys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tablodaki veriyi kopyalamak için CTRL veya u2318 + C tuşlarına basınız. İptal etmek için bu mesaja tıklayın veya escape tuşuna basın."])},
    "createState": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şuanki Görünümü Kaydet"])},
    "removeAllStates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tüm Görünümleri Sil"])},
    "removeState": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktif Görünümü Sil"])},
    "renameState": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktif Görünümün Adını Değiştir"])},
    "savedStates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaydedilmiş Görünümler"])},
    "stateRestore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görünüm -&gt; %d"])},
    "updateState": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktif Görünümün Güncelle"])}
  },
  "searchBuilder": {
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koşul Ekle"])},
    "button": {
      "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arama Oluşturucu"])},
      "_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arama Oluşturucu (%d)"])}
    },
    "condition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koşul"])},
    "conditions": {
      "date": {
        "after": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sonra"])},
        "before": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Önce"])},
        "between": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arasında"])},
        "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Boş"])},
        "equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eşittir"])},
        "not": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Değildir"])},
        "notBetween": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dışında"])},
        "notEmpty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dolu"])}
      },
      "number": {
        "between": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arasında"])},
        "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Boş"])},
        "equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eşittir"])},
        "gt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Büyüktür"])},
        "gte": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Büyük eşittir"])},
        "lt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Küçüktür"])},
        "lte": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Küçük eşittir"])},
        "not": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Değildir"])},
        "notBetween": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dışında"])},
        "notEmpty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dolu"])}
      },
      "string": {
        "contains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İçerir"])},
        "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Boş"])},
        "endsWith": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İle biter"])},
        "equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eşittir"])},
        "not": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Değildir"])},
        "notEmpty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dolu"])},
        "startsWith": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İle başlar"])},
        "notContains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İçermeyen"])},
        "notStartsWith": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Başlamayan"])},
        "notEndsWith": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitmeyen"])}
      },
      "array": {
        "contains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İçerir"])},
        "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Boş"])},
        "equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eşittir"])},
        "not": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Değildir"])},
        "notEmpty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dolu"])},
        "without": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hariç"])}
      }
    },
    "data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veri"])},
    "deleteTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtreleme kuralını silin"])},
    "leftTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kriteri dışarı çıkart"])},
    "logicAnd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ve"])},
    "logicOr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["veya"])},
    "rightTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kriteri içeri al"])},
    "title": {
      "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arama Oluşturucu"])},
      "_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arama Oluşturucu (%d)"])}
    },
    "value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Değer"])},
    "clearAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtreleri Temizle"])}
  },
  "searchPanes": {
    "clearMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hepsini Temizle"])},
    "collapse": {
      "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arama Bölmesi"])},
      "_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arama Bölmesi (%d)"])}
    },
    "count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("total"))])},
    "countFiltered": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("shown")), "/", _interpolate(_named("total"))])},
    "emptyPanes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arama Bölmesi yok"])},
    "loadMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arama Bölmeleri yükleniyor ..."])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etkin filtreler - %d"])},
    "showMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tümünü Göster"])},
    "collapseMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tümünü Gizle"])}
  },
  "thousands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["."])},
  "datetime": {
    "amPm": [
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["öö"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ös"])}
    ],
    "hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saat"])},
    "minutes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dakika"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sonraki"])},
    "previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Önceki"])},
    "seconds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saniye"])},
    "unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bilinmeyen"])},
    "weekdays": {
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paz"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cmt"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cum"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Çar"])},
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sal"])},
      "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pzt"])}
    },
    "months": {
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ekim"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eylül"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ağustos"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temmuz"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haziran"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mayıs"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nisan"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mart"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aralık"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kasım"])},
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şubat"])},
      "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ocak"])}
    }
  },
  "decimal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([","])},
  "editor": {
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kapat"])},
    "create": {
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni"])},
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaydet"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni kayıt oluştur"])}
    },
    "edit": {
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Düzenle"])},
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Güncelle"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaydı düzenle"])}
    },
    "error": {
      "system": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bir sistem hatası oluştu (Ayrıntılı bilgi)"])}
    },
    "multi": {
      "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seçili kayıtlar bu alanda farklı değerler içeriyor. Seçili kayıtların hepsinde bu alana aynı değeri atamak için buraya tıklayın; aksi halde her kayıt bu alanda kendi değerini koruyacak."])},
      "noMulti": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu alan bir grup olarak değil ancak tekil olarak düzenlenebilir."])},
      "restore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Değişiklikleri geri al"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Çoklu değer"])}
    },
    "remove": {
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sil"])},
      "confirm": {
        "_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["%d adet kaydı silmek istediğinize emin misiniz?"])},
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu kaydı silmek istediğinizden emin misiniz?"])}
      },
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sil"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kayıtları sil"])}
    }
  },
  "stateRestore": {
    "creationModal": {
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaydet"])},
      "columns": {
        "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kolon Araması"])},
        "visible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kolon Görünümü"])}
      },
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görünüm İsmi"])},
      "order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sıralama"])},
      "paging": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sayfalama"])},
      "scroller": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaydırma (Scrool)"])},
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arama"])},
      "searchBuilder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arama Oluşturucu"])},
      "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seçimler"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni Görünüm Oluştur"])},
      "toggleLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaydedilecek Olanlar"])}
    },
    "duplicateError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu Görünüm Daha Önce Tanımlanmış"])},
    "emptyError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görünüm Boş Olamaz"])},
    "emptyStates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Herhangi Bir Görünüm Yok"])},
    "removeJoiner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ve"])},
    "removeSubmit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sil"])},
    "removeTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görünüm Sil"])},
    "renameButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Değiştir"])},
    "renameLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görünüme Yeni İsim Ver -&gt; %s:"])},
    "renameTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görünüm İsmini Değiştir"])},
    "removeConfirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görünümü silmek istediğinize emin misiniz?"])},
    "removeError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görünüm silinemedi"])}
  }
}