<template>
    <div class="row mt-3">
        <div class="col-6">
            <a href="'#/vendors" class="btn btn-sm btn-light"><i class="fa fa-chevron-left"></i> {{ $t('backTo') }}</a>
            <h3>{{ $t('bulkInsertVendor') }}</h3>
        </div>
        <div class="col-12">
            <hr/>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6">
            <div class="row">
                <div class="col-12 mb-3">
                    <span class="badge bg-secondary">{{ $t('bulkInsertVendorHelpText') }}</span><br><br>
                    <a :href="baseUrl +'templates/vendor_template.xlsx'" target="_blank">{{ $t('downloadTemplateFile') }}</a>
                </div>
                <div class="col-12 mb-3">
                    <input type="file" @change="handleFileChange">
                    <button @click="importVendor" :disabled="!selectedFile" class="btn btn-sm btn-success">{{ $t('uploadExcelFile') }}</button>
                </div>
            </div>
            
        </div>
        <div class="col-12">
            <hr/>
        </div>
        <div class="col-12">
            <table class="table table-hover" v-if="vendorData.length>0">
                <thead>
                    <tr>
                        <th scope="col">{{$t('displayName')}}</th>
                        <th scope="col">{{$t('phoneNumber')}}</th>
                        <th scope="col">{{$t('description')}}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(p,index) in vendorData" :key="index">
                        <td>{{ p.displayName }}</td>
                        <td>{{ p.phoneNumber }}</td>
                        <td>{{ p.processDescription }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="col-12">
            <table class="table table-hover" v-if="errorList.length>0">
                <thead>
                    <tr>
                        <th scope="col">{{$t('displayName')}}</th>
                        <th scope="col">{{$t('phoneNumber')}}</th>
                        <th scope="col">{{$t('description')}}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(p,index) in errorList" :key="index">
                        <td>{{ p.displayName }}</td>
                        <td>{{ p.phoneNumber }}</td>
                        <td>{{ p.processDescription }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>
<script>

import { collection, doc, getDoc, addDoc, setDoc, updateDoc, serverTimestamp, deleteDoc, query, where, getDocs  } from "firebase/firestore";
import { getFirestore } from "firebase/firestore";
import db from '../firebase/init';
import { getFunctions, httpsCallable } from "firebase/functions";
import Swal from 'sweetalert2';
import ExcelJS from 'exceljs';

export default {
    components: {

    },
    data(){
        return {
            formData: {
            },
            vendorData:[],
            errorList:[],
            selectedFile: null,
            baseUrl: process.env.BASE_URL 
        }
    },
    async mounted() {
           
    },  
    methods: {
        handleFileChange(event) {
            this.selectedFile = event.target.files[0];
        },
        async importVendor(){
            if(this.selectedFile!=null){

                const reader = new FileReader();

                reader.onload = (e) => {
                    this.$setLoading(true);
                    const data = new Uint8Array(e.target.result);

                    const workbook = new ExcelJS.Workbook();
                    workbook.xlsx.load(data).then(() => {
                        const worksheet = workbook.getWorksheet(1);
                        let rowCount = 0;
                        this.vendorData=[];
                        
                        let vendorList = [];

                        worksheet.eachRow({ includeEmpty: false, stopAtFirstEmpty: true }, async (row) => {
                            rowCount++;
                            if(rowCount>1){//Başlıklar için
                                const taxNumber = row.getCell('A'); //taxNumber.value
                                const displayName = row.getCell('B'); //displayName.value
                                const customCode = row.getCell('C'); //customCode.value
                                const email = row.getCell('D'); //email.value.text
                                const phoneNumber = row.getCell('E'); //phoneNumber.value
                                /*console.log("taxNumber",taxNumber.value);
                                console.log("displayName",displayName.value);
                                console.log("customCode",customCode.value);
                                console.log("email",email.value);
                                console.log("emailValue",email.value.text);
                                console.log("phoneNumber",phoneNumber.value);*/

                                if(taxNumber.value!=null && taxNumber.value!=undefined && displayName.value!=null && displayName.value!=undefined && customCode.value!=null && customCode.value!=undefined && email.value!=null && email.value!=undefined && phoneNumber.value!=null && phoneNumber.value!=undefined){

                                    try {
                                                                                
                                        let emailValue = "";
                                        if (typeof email.value === 'string'){
                                            emailValue = email.value;
                                        }else{
                                            emailValue = email.value.text;
                                        }

                                        let phoneNumberValue = phoneNumber.value.toString();
                                        phoneNumberValue = phoneNumberValue.split(' ').join('');
                                        phoneNumberValue = phoneNumberValue.split('(').join('');
                                        phoneNumberValue = phoneNumberValue.split(')').join('');
                                        if(phoneNumberValue.length == 10 && !phoneNumberValue.startsWith("+90")){
                                            phoneNumberValue = '+90'+phoneNumberValue;
                                        }

                                        let taxNumberValue = parseFloat(taxNumber.value);

                                        vendorList.push({
                                            taxNumber: taxNumberValue, 
                                            customCode: customCode.value, 
                                            email: emailValue, 
                                            phoneNumber: phoneNumberValue, 
                                            displayName: displayName.value
                                        });

                                    } catch (error) {
                                        this.errorList.push({
                                            displayName: displayName.value,
                                            phoneNumber: phoneNumber.value,
                                            processDescription: this.$t('error')+' '+error
                                        });
                                    }

                                }else{

                                    this.errorList.push({
                                        displayName: displayName.value,
                                        phoneNumber: phoneNumber.value,
                                        processDescription: this.$t('missingInformation')
                                    });
                                }
                            }
                            
                        });

                        if(vendorList.length > 0){

                            const functions = getFunctions();      
                            let strUserList = JSON.stringify(vendorList);
                            httpsCallable(functions,"createBulkVendorUser")({userList: strUserList}).then(async result => {
                                this.$setLoading(false);
                                if(result.data.results!=null && result.data.results!=undefined){
                                    var resutlList = result.data.results;
                                    resutlList.forEach((item, index) => {
                                        if(item.success){
                                            this.vendorData.push({
                                                displayName: item.data.displayName,
                                                phoneNumber: item.data.phoneNumber,
                                                processDescription: this.$t('saveSuccessful')
                                            });
                                        }else{
                                            this.vendorData.push({
                                                displayName: item.data.displayName,
                                                phoneNumber: item.data.phoneNumber,
                                                processDescription: this.$t('error')+' '+item.message
                                            });
                                        }
                                    });
                                }
                                Swal.fire({
                                    title: this.$t('success'),
                                    text: this.$t('saveSuccessful'),
                                    icon: 'success',
                                    confirmButtonText: this.$t('ok'),
                                    confirmButtonColor: '#0f7438',
                                });
                                
                            })
                            .catch(error => {
                                this.$setLoading(false);
                                console.log(error);
                                Swal.fire({
                                    title: this.$t('error'),
                                    text: this.$t('error')+ ' '+error,
                                    icon: 'error',
                                    confirmButtonText: this.$t('ok')
                                });
                            });

                        }else{
                            this.$setLoading(false);
                        }

                    });
                };


                reader.readAsArrayBuffer(this.selectedFile);
                this.selectedFile = null;
            }
        },
    },

}
</script>

