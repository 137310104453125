import { initializeApp, getApp } from 'firebase/app';
import { getFirestore, connectFirestoreEmulator, collection, getDoc, doc } from 'firebase/firestore';
import {getFunctions, connectFunctionsEmulator} from "@firebase/functions";
import {getStorage, connectStorageEmulator} from "firebase/storage";

const firebaseConfig = {
    apiKey: "AIzaSyAe3uHgTMU5mmSCCXMtCbyNPkuxt8DoBlE",
    authDomain: "veganic-app.firebaseapp.com",
    projectId: "veganic-app",
    storageBucket: "veganic-app.appspot.com",
    messagingSenderId: "1090635658678",
    appId: "1:1090635658678:web:53157c553915eb95027d17",
    measurementId: "G-F712S55RJK"
}

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
const functions = getFunctions(firebaseApp);
const storage = getStorage(firebaseApp);

const db = getFirestore(firebaseApp);

if(window.location.hostname === 'localhost') {
    connectFunctionsEmulator(functions, 'localhost', 5001);
    connectStorageEmulator(storage, "localhost", 9199);
    connectFirestoreEmulator(db, "localhost", 5002);
    /* OLD implementation */
    // Firebase.firestore().settings({ host: 'localhost:8080', ssl: false });
    // Firebase.functions().useFunctionsEmulator('http://localhost:5001');
}

// Get Firestore instance


export default db;
