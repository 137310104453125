export default {
  "convertCustomerToEmployee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Çalışanı Dönüştür"])},
  "fillTheForm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aşağıdaki formu doldurun"])},
  "convert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dönüştür"])},
  "yourEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail Adresiniz"])},
  "yourPhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefon Numaranız"])},
  "yourPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şifreniz"])},
  "pleaseEnterPhoneAndPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lütfen telefon numaranızı ve şifrenizi giriniz"])},
  "pleaseEnterEmailAndPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lütfen e-mail adresinizi ve şifrenizi giriniz"])},
  "pleaseEnterEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lütfen e-mail adresinizi giriniz"])},
  "invalidEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geçersiz mail adresi"])},
  "adminInformationIncorrect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yönetici e-mail adresi veya şifre hatalı!"])},
  "forgetPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şifrenizi mi unuttunuz?"])},
  "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Giriş Yap"])},
  "sendVerifyCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doğrulama Kodu Gönder"])},
  "sendEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mail Gönder"])},
  "sendEmailSuccessfullyMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şifre sıfırlama maili gönderildi. Lütfen spam kutunuzu da kontrol ediniz"])},
  "sendEmailFailedMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mail adresi sisteme kayıtlı değil, kullanıcı bulunamadı"])},
  "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Çıkış Yap"])},
  "backTo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geri Dön"])},
  "tr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Türkçe"])},
  "en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İngilizce"])},
  "de": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Almanca"])},
  "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktif"])},
  "pasive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pasif"])},
  "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Düzenle"])},
  "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sil"])},
  "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaydet"])},
  "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tamam"])},
  "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vazgeç"])},
  "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İşlem Başarılı"])},
  "saveSuccessful": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaydedildi"])},
  "deleteSuccessful": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kayıt silindi"])},
  "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hata Oluştu!"])},
  "warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uyarı!"])},
  "areYouSure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emin misiniz?"])},
  "areYouSureDelete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veriyi silmek istediğinize emin misiniz?"])},
  "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yükleniyor..."])},
  "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard"])},
  "annualSalesReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yıllık Satış Raporu"])},
  "webNotifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Web Bildirimleri"])},
  "createdDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kayıt Tarihi"])},
  "january": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ocak"])},
  "february": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şubat"])},
  "march": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mart"])},
  "april": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nisan"])},
  "may": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mayıs"])},
  "june": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haziran"])},
  "july": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temmuz"])},
  "august": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ağustos"])},
  "september": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eylül"])},
  "october": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ekim"])},
  "november": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kasım"])},
  "december": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aralık"])},
  "stores": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kuaförler"])},
  "store": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kuaför"])},
  "bulkInsertStore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toplu Kuaför Yükle"])},
  "bulkInsertStoreHelpText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yükleyeceğiniz dosya excel(.xlsx) uzantılı olmalıdır. Örnek excel dosyasını değiştirerek kullanabilirsiniz. Sütun sıralamaları değiştirilmemelidir. Telefon formatı başında ülke kodu içerecek şekilde birleşik olarak yazılmalıdır. Örnek format: +905xxxxxxxxx"])},
  "addNewStore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni Kuaför Ekle"])},
  "editStore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kuaför Düzenle"])},
  "taxNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vergi No"])},
  "advisor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Danışman"])},
  "storeName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kuaför Adı"])},
  "signageName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tabela Adı"])},
  "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durumu"])},
  "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Açıklama"])},
  "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
  "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefon numarası"])},
  "phoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefon numarası"])},
  "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İl"])},
  "district": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İlçe"])},
  "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adres"])},
  "isActive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktif mi?"])},
  "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ayarlar"])},
  "isOrderActive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sipariş Aktif mi?"])},
  "isPointSystemActive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puan Sistemi Aktif mi?"])},
  "isGiveawayActive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Çekiliş Sistemi Aktif mi?"])},
  "isDonationActive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bağış Sistemi Aktif mi?"])},
  "contactEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İletişim E-mail Adresi"])},
  "contactPhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İletişim Telefon Numarası"])},
  "pages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bilgi Sayfaları"])},
  "addNewPage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni Sayfa Ekle"])},
  "editPage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sayfa Düzenle"])},
  "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Başlık"])},
  "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alt Başlık"])},
  "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dil"])},
  "languageCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dil Kodu"])},
  "pageKey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sayfa Anahtarı"])},
  "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İçerik"])},
  "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategoriler"])},
  "addNewCategory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni Kategori Ekle"])},
  "editCategory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategori Düzenle"])},
  "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adı"])},
  "categoryType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategori Tipi"])},
  "products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ürünler"])},
  "product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ürün"])},
  "addNewProduct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni Ürün Ekle"])},
  "editProduct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ürün Düzenle"])},
  "smallImage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ürün Küçük Resmi"])},
  "smallImageText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ürün Küçük Resim Açıklaması"])},
  "categoryName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategori Adı"])},
  "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategori"])},
  "orderNo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sıra Numarası"])},
  "uploadImage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görsel Yükle"])},
  "uploadSmallImage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Küçük Görsel Yükle"])},
  "productImageHelpText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ürün görseli olarak mümkün olduğunca boyutu düşük ve 500x500 px boyutunda bir görsel yükleyin."])},
  "productStoreDetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kuaförler İçin Açıklama"])},
  "vendorEarnPoint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Satıcının Kazanacağı Puan"])},
  "customerEarnPoint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Müşterinin Kazanacağı Puan"])},
  "pleaseChooseCategory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lütfen Kategori Seçiniz"])},
  "pleaseUploadImage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lütfen Görsel Yükleyiniz"])},
  "productBarcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ürün Barkodu"])},
  "pleaseEnterProductBarcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lütfen Ürün Barkodunu Giriniz"])},
  "donations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bağış Kuruluşu"])},
  "addNewDonation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni Bağış Kuruluşu Ekle"])},
  "editDonation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bağış Kuruluşu Düzenle"])},
  "donationImageHelpText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bağış kuruluşu görseli olarak mümkün olduğunca boyutu düşük ve 500x500 px boyutunda bir görsel yükleyin."])},
  "giveaways": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Çekilişler"])},
  "giveaway": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Çekiliş"])},
  "addNewGiveaway": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni Çekiliş Ekle"])},
  "editGiveaway": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Çekiliş Düzenle"])},
  "uploadGiveawayCodes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Çekiliş Kodu Yükle"])},
  "giveawayResultContent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Çekiliş Sonuçları İçeriği"])},
  "giveawayImageHelpText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Çekiliş görseli olarak mümkün olduğunca boyutu düşük ve 500x500 px boyutunda bir görsel yükleyin."])},
  "addProductQr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ürün QR Tanımlama"])},
  "qrCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QR Kod"])},
  "soldStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Satılma Durumu"])},
  "sold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Satıldı"])},
  "notSold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Satılmadı"])},
  "uploadExcelFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excel Dosyasını Yükle"])},
  "productQrHelpText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yükleyeceğiniz dosya excel(.xlsx) uzantılı olmalıdır. İlk sütunda QR kodları yer almalıdır."])},
  "noDataFoundExcel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excelde veri bulunamadı"])},
  "qrDataAddedToProduct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QR verileri ürüne eklendi"])},
  "vendors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kuaför Çalışanları"])},
  "addNewVendor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni Çalışan Ekle"])},
  "bulkInsertVendor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toplu Çalışan Yükle"])},
  "bulkInsertVendorHelpText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yükleyeceğiniz dosya excel(.xlsx) uzantılı olmalıdır. Örnek excel dosyasını değiştirerek kullanabilirsiniz. Sütun sıralamaları değiştirilmemelidir. Telefon formatı başında ülke kodu içerecek şekilde birleşik olarak yazılmalıdır. Örnek format: +905xxxxxxxxx"])},
  "editVendor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Çalışan Düzenle"])},
  "displayName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İsim"])},
  "nameSurname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ad Soyad"])},
  "customCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Özel Kod"])},
  "isBlocked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Engellendi mi?"])},
  "vendorAdded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kuaför çalışanı eklendi"])},
  "vendorUpdated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kuaför çalışanı güncellendi"])},
  "customers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Müşteriler"])},
  "totalOrderCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toplam Sipariş"])},
  "salesReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Satış Raporları"])},
  "salesReportV2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Satış Raporları V2"])},
  "sale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Satış"])},
  "return": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İade"])},
  "transactionType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Satış Tipi"])},
  "customerCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Müşteri Kodu"])},
  "customerName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Müşteri Adı"])},
  "editCustomer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Müşteri Düzenle"])},
  "customerUpdated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Müşteri güncellendi"])},
  "transferPoints": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puan Aktar"])},
  "bulkTransferPoints": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toplu Puan Aktar"])},
  "noPointPendingTransfer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktarılmayı bekleyen puan bulunmuyor"])},
  "xPointTransferred": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["puan aktarıldı"])},
  "transferPointsHelpText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yükleyeceğiniz dosya excel(.xlsx) uzantılı olmalıdır. İlk sütunda kullanıcıların özel kodu yer almalıdır."])},
  "productName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ürün Adı"])},
  "donationName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bağış Yapılan Kurum"])},
  "giveawayName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Çekiliş Adı"])},
  "giveawayNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Çekiliş Numarası"])},
  "vendorStoreId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kuaför Mağaza Kodu"])},
  "vendorName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kuaför Ad Soyad"])},
  "vendorStoreName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kuaför Salon Adı"])},
  "vendorCustomCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kuaför Özel Kod"])},
  "vendorCreatedDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kuaför Kayıt Tarihi"])},
  "vendorPointAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kuaför Kazanılan Puan"])},
  "exportExcel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excel'e Aktar"])},
  "totalPoints": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toplam Puan"])},
  "loadedPoints": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yüklenen Puan"])},
  "pendingPoints": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bekleyen Puan"])},
  "transferPoint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktarılacak Puan"])},
  "transfer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktar"])},
  "transferPointInvalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puan geçerli değil. Aktarılacak puan, bekleyen puandan daha yüksek olamaz"])},
  "transferSuccessful": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puan aktarıldı"])},
  "dateRangeWarning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitiş tarihi başlangıç tarihinden büyük olmalı"])},
  "pushNotifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobil Bildirimler"])},
  "sendPushNotification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobil Bildirim Gönder"])},
  "userType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kullanıcı Tipi"])},
  "allUserTypes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tüm Kullanıcı Tipleri"])},
  "usersChoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kullanıcı Seçimi"])},
  "allSelected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tümünü Seç"])},
  "clearSelect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seçilenleri Temizle"])},
  "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gönder"])},
  "pleaseEnterTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Başlık ve açıklama alanlarını doldurunuz"])},
  "pleaseSelectUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lütfen kullanıcı seçiniz"])},
  "maxSelectUserLimit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tek seferde maksimum 500 kullanıcı seçilebilir"])},
  "pushNotificationSent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bildirim ilgili kullanıcılara gönderildi"])},
  "successCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Başarılı Sayısı"])},
  "failureCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Başarısız Sayısı"])},
  "sliders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobil Kayan Görseller"])},
  "addNewSlider": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni Kayan Görsel Ekle"])},
  "editSlider": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kayan Görsel Düzenle"])},
  "sliderImageHelpText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobil kayan görseli olarak mümkün olduğunca boyutu düşük ve 700x400 px boyutunda bir görsel yükleyin."])},
  "giveawayCodesHelpText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yükleyeceğiniz dosya excel(.xlsx) uzantılı olmalıdır. İlk sütunda çekiliş kodları yer almalıdır."])},
  "isUsed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kullanıldı mı?"])},
  "used": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kullanıldı"])},
  "giveawayCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Çekiliş Kodu"])},
  "missingInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eksik bilgiler mevcut"])},
  "downloadTemplateFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Örnek Taslak Dosyayı İndir"])},
  "noStoreFoundForTaxNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vergi numarasına ait kuaför bulunamadı"])},
  "giftRequestList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hediye Talep Listesi"])},
  "selectedCategory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seçilen Kategori"])},
  "selectedProduct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seçilen Ürün"])},
  "information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bilgilendirme"])},
  "noSalesBetweenTwoDates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İki tarih arasında satış kaydı bulunmamaktadır"])},
  "categoryCoverPhotoHelpText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategori kapak görseli olarak mümkün olduğunca boyutu düşük ve 700x300 px boyutunda bir görsel yükleyin."])},
  "categoryImageHelpText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategori liste görseli olarak mümkün olduğunca boyutu düşük bir görsel yükleyin."])},
  "categoryCoverPhoto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategori Kapak Görseli"])},
  "categoryImage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategori Liste Görseli"])},
  "addresses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresleri"])},
  "addressTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adres Başlığı"])},
  "giveawayReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Çekiliş Raporu"])},
  "selectGiveawayPlease": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Çekiliş Seçimi Yapınız"])},
  "noGiveawayCodes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Çekiliş kodu bulunamadı"])},
  "usedDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kullanım Tarihi"])},
  "fontColor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metin Rengi"])},
  "importQrCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QR Kod İçe Aktar"])},
  "startTransfer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transferi Başlat"])},
  "checkProductQr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ürün Kontrol"])},
  "checkProductStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ürün Durumu Sorgulama"])},
  "qrPn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product Number"])},
  "qrSn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seri No"])},
  "query": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorgula"])},
  "fillAllQuery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tüm alanları doldurunuz"])},
  "qrNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QR Kod Bulunamadı"])},
  "checkGiveAways": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Çekiliş Kontrol"])},
  "codeNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kod Bulunamadı"])},
  "makeEmptyCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kodu Sıfırla"])},
  "codeEmptyMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kodu Sıfırlamak istediğinizden eminmisiniz"])},
  "codeEmpty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sıfırla"])},
  "codeJoin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eşleştir"])},
  "checkGiveawayStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kampanya Kodu Sorgulama"])},
  "joinGiveAwayByAdmin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kodu kullanıcıya tanımla"])},
  "codeJoinMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kodu eşleştirmek istediğinizden eminmisiniz"])}
}