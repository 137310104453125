<template>
    <div class="row mt-3">
        <div class="col-6">
            <h3>{{ $t('settings') }}</h3>
        </div>
        <div class="col-12">
            <hr/>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6">
            <form @submit.prevent="submitForm">
            <div class="mb-3">
                <label for="isOrderActive" class="form-check-label">{{ $t('isOrderActive') }}</label>
                <input type="checkbox" id="isOrderActive" v-model="formData.isOrderActive" class="form-check-input">
            </div>
            <div class="mb-3">
                <label for="isPointSystemActive" class="form-check-label">{{ $t('isPointSystemActive') }}</label>
                <input type="checkbox" id="isPointSystemActive" v-model="formData.isPointSystemActive" class="form-check-input">
            </div>
            <div class="mb-3">
                <label for="isGiveawayActive" class="form-check-label">{{ $t('isGiveawayActive') }}</label>
                <input type="checkbox" id="isGiveawayActive" v-model="formData.isGiveawayActive" class="form-check-input">
            </div>
            <div class="mb-3">
                <label for="isDonationActive" class="form-check-label">{{ $t('isDonationActive') }}</label>
                <input type="checkbox" id="isDonationActive" v-model="formData.isDonationActive" class="form-check-input">
            </div>
            <div class="mb-3" v-if="false">
                <label for="contactEmail" class="form-label">{{ $t('contactEmail') }}</label>
                <input type="email" id="contactEmail" v-model="formData.contactEmail" class="form-control" required>
            </div>
            <div class="mb-3" v-if="false">
                <label for="contactPhone" class="form-label">{{ $t('contactPhone') }}</label>
                <input type="tel" id="contactPhone" v-model="formData.contactPhone" v-maska data-maska="+90 (###) ### ## ##" class="form-control" placeholder="+90 (xxx) xxx xx xx" required>
            </div>
            <button type="submit" class="btn btn-success">{{ $t('save') }}</button>
            </form>
        </div>
    </div>
</template>
<script>
import { vMaska } from "maska";
import { collection, doc, getDoc, addDoc, updateDoc, serverTimestamp  } from "firebase/firestore";
import { getFirestore } from "firebase/firestore";
import Swal from 'sweetalert2';

export default {
    directives: { maska: vMaska },
    components: {
        
    },
    data(){
        return {
            formData: {
                isOrderActive: true,
                isPointSystemActive: true,
                isGiveawayActive: true,
                isDonationActive: true,
                contactPhone: '',
                contactEmail: ''
            }
        }
    },
    async mounted() {
        const settingData = await this.getSettings();

        if (settingData) {
            this.formData = { ...settingData };
        }
    },  
    methods: {
        async getSettings() {
            
            const documentRef = doc(getFirestore(), 'settings', 'project');
            const documentSnapshot = await getDoc(documentRef);

            if (documentSnapshot.exists()) {
                return {
                ...documentSnapshot.data()
                };
            }
            return null;
        },
        async submitForm() {
            try {
                this.formData.contactPhone = this.$setPhone(this.formData.contactPhone);

                const documentRef = doc(getFirestore(), 'settings', 'project');
                await updateDoc(documentRef, { ...this.formData });

                Swal.fire({
                    title: this.$t('success'),
                    text: this.$t('saveSuccessful'),
                    icon: 'success',
                    confirmButtonText: this.$t('ok'),
                    confirmButtonColor: '#0f7438',
                });
                
            } catch (error) {
                Swal.fire({
                    title: this.$t('error'),
                    text: error,
                    icon: 'error',
                    confirmButtonText: this.$t('ok')
                });
            }
        },
    },

}
</script>

