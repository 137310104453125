<template>
  <div class="row mt-3 vld-parent">
  <loading :active="isLoading"
           :can-cancel="true"
           :is-full-page="fullPage" >

  </loading>
    <div class="col-6">
<!--      <a href="#/" class="btn btn-sm btn-light"><i class="fa fa-chevron-left"></i> {{ $t('backTo') }}</a>-->
      <h3>{{ $t('checkGiveawayStatus') }}</h3>
    </div>
    <div class="col-12">
      <hr/>
    </div>
  </div>
  <div class="row">
        <div class="col-6 mb-3">
          <label for="giveAwayId" class="form-label">{{ $t('giveawayName') }}</label>
          <Select2 id="giveAwayId" v-model="formData.giveAway" :options="giveAwaysList">
            <option v-for="giveAway in giveAways" :value="giveAway['id']" :key="giveAway['id']">{{ giveAway['text'] }}</option>
          </Select2>
        </div>
        <div class="col-2 mb-3">
          <label for=giveAwayCode class="form-label">{{ $t('giveawayNumber') }}</label>
          <input type="text" id="giveAwayCode" v-model="formData.giveAwayCode" class="form-control" required>
        </div>

        <div class="col-2 mb-3">
          <label class="form-label">&nbsp;</label>
          <button class="btn btn-sm btn-success form-control my-1" @click="checkGiveAwayStatus">{{ $t('query') }}</button>
        </div>
  </div>
<div>
  <div class="row" v-if="this.messageType === 'fillAllQuery'">
    <div class="col">
      {{$t("fillAllQuery")}}
    </div>
  </div>
  <div class="row" v-if="this.messageType === 'qrNotFound'">
    <div class="col">
      {{$t("qrNotFound")}}
    </div>
  </div>
<!--  <div v-for="item in giveAwaysList" v-bind:key="item['id']">
    <span>{{item['id']}}</span>
    <button class="btn btn-sm btn-success form-control my-1" @click="showAlert(item)">{{ $t('makeEmptyCode') }}</button>
  </div>-->

    <div  v-for="item in this.giveAwayCodes" v-bind:key="item.code">
      <div class="card">
        <div class="card-header">
          <div class="d-flex">
            <div class="flex-fill">
              {{this.selectedGiveAway?.title}} - <strong>{{this.formData.giveAwayCode}}</strong>
            </div>
          </div>
        </div>
        <div class="card-body">
          <div class="row" v-if="item['userId'] !== null" >
            <div class="col">
                <div class="row">
                  <div class="col">{{ item.displayName }}</div>
                  <div class="col">{{ item.phoneNumber }}</div>
                  <div class="col">{{ formatDate(item.usedDate?.seconds) }}</div>

                  <div class="col mb-3">
                    <button class="btn btn-sm btn-success form-control my-1" @click="seperateGiveaway(item)">{{ $t('makeEmptyCode') }}</button>
                  </div>
                </div>
            </div>
          </div>

          <div class="row" v-if="item['userId'] === null" >

                  <div class="col-4 mb-3">
                    <label for=giveAwayCode class="form-label">{{ $t('email') }}</label>
                    <input type="text" id="giveAwayCode" v-model="formData.email" class="form-control" required>
                  </div>
                  <div class="col-3 mb-3">
                    <label class="form-label">&nbsp;</label>
                    <button class="btn btn-sm btn-success form-control my-1" @click="joinGiveaway(item)">{{ $t('joinGiveAwayByAdmin') }}</button>
                  </div>
        </div>

        </div>
      </div>
    </div>

</div>

</template>
<script>

import '../assests/app.css'
import { ref } from 'vue';
// Import component
import Loading from 'vue3-loading-overlay';
// Import stylesheet
import 'vue3-loading-overlay/dist/vue3-loading-overlay.css';
import { query, collection, getDocs, getDoc, doc,  where } from "firebase/firestore";
import db from '../firebase/init';
import Select2 from 'vue3-select2-component'
import Swal from "sweetalert2";
import {getFunctions, httpsCallable} from "firebase/functions";


export default {
    components: {
      Loading,
      Select2
    },
    data(){
        return{
          items: [],

          formData: {
            giveAway: "",
            giveAwayCode: "",
            email: "",
          },
          giveAways: [],
          giveAwayCodes: [],
          giveAwaysList: [],
          messageType: null,
          selectedGiveAway: null,

          isLoading: ref(false),
          fullPage: ref(true),
        }
    },
    async mounted() {

      /*await this.getProducts().then(()=>{
        this.products.forEach(item => {
            this.productList.push({
              id: item.id,
              text: item.name  + ' (' + item.id + ')',
            });
        });
      });*/

      await this.getGiveAways().then(()=>{
        this.giveAways.forEach(item => {
            this.giveAwaysList.push({
              id: item.id,
              text: item.title  + ' (' + item.languageCode + ') (' +item.active + ')',
            });
        });
      });
    },
    methods: {
      showLoading() {
        this.isLoading = true;
        setTimeout(() => {
          this.isLoading = false
        },60000)
      },
      hideLoading() {
        this.isLoading = false;
      },
      seperateGiveaway(item) {

        const functions = getFunctions();

        Swal.fire({
          title: this.$t('areYouSure'),
          text: this.$t('codeEmptyMessage'),
          icon: 'question',
          showCancelButton: true,
          confirmButtonColor: '#0f7438',
          cancelButtonColor: '#6c757d',
          confirmButtonText: this.$t('codeEmpty'),
          cancelButtonText: this.$t('cancel')
        }).then(async (result) => {
          if (result.isConfirmed) {
            this.$setLoading(true);
            httpsCallable(functions,"seperateGiveaway")(
                {
                  giveawayId: this.selectedGiveAway.id,
                  giveawayPassword: item.code,
                  userDocId: item.giveAwayUserDocId
                }).then(async result => {
              this.$setLoading(false);
                if(result.data.success === true){
                  await Swal.fire({
                    title: this.$t('success'),
                    text: result.data.message,
                    icon: 'success',
                    confirmButtonText: this.$t('ok')
                  });
                }else{
                  await Swal.fire({
                    title: this.$t('error'),
                    text: result.data.message,
                    icon: 'error',
                    confirmButtonText: this.$t('ok')
                  });
                }

              this.checkGiveAwayStatus();
            })
                .catch(error => {
                  this.$setLoading(false);
                  Swal.fire({
                    title: this.$t('error'),
                    text: error.message,
                    icon: 'error',
                    confirmButtonText: this.$t('ok')
                  });

                  this.checkGiveAwayStatus();
                });
          }
        });
      },

      joinGiveaway(item) {

        const functions = getFunctions();

        Swal.fire({
          title: this.$t('areYouSure'),
          text: this.$t('codeJoinMessage'),
          icon: 'question',
          showCancelButton: true,
          confirmButtonColor: '#0f7438',
          cancelButtonColor: '#6c757d',
          confirmButtonText: this.$t('codeJoin'),
          cancelButtonText: this.$t('cancel')
        }).then(async (result) => {
          if (result.isConfirmed) {
            this.$setLoading(true);
            httpsCallable(functions,"joinGiveawayByAdmin")(
                {
                  giveawayId: this.selectedGiveAway.id,
                  giveawayPassword: item.code,
                  userEmail: this.formData.email
                }).then(async result => {
              this.$setLoading(false);
              if(result.data.success === true){
                await Swal.fire({
                  title: this.$t('success'),
                  text: result.data.message,
                  icon: 'success',
                  confirmButtonText: this.$t('ok')
                });
              }else{
                await Swal.fire({
                  title: this.$t('error'),
                  text: result.data.message,
                  icon: 'error',
                  confirmButtonText: this.$t('ok')
                });

              }
              this.checkGiveAwayStatus();
            })
                .catch(error => {
                  this.$setLoading(false);
                  Swal.fire({
                    title: this.$t('error'),
                    text: error.message,
                    icon: 'error',
                    confirmButtonText: this.$t('ok')
                  });


                  this.checkGiveAwayStatus();
                });


          }
        });
      },
        formatDate(dateString) {
        if(!dateString) return;
          const date =  new Date(dateString * 1000);
          return new Intl.DateTimeFormat('default', {dateStyle: 'long', timeStyle: 'short',}).format(date);
        },

      async getGiveAways() {
        const querySnap = await getDocs(query(collection(db, 'giveaways')));
        this.giveAways = querySnap.docs.map((doc) => {
          return {
            id: doc.id,
            title: doc.data().title,
            active: doc.data().active,
            languageCode: doc.data().languageCode,
          }
        })
      },


      checkGiveAwayStatus: async function () {
        this.showLoading();
        const selectedGiveAway = this.giveAways.filter(v => {
          return v.id === this.formData.giveAway
        });
        if (selectedGiveAway.length > 0) {
          this.selectedGiveAway = selectedGiveAway[0];
        } else {
          this.selectedGiveAway = null;
        }
        this.messageType = null;
        if (this.formData.giveAway == null || this.formData.giveAwayCode == null) {
          this.messageType = "fillAllQuery";
          return;
        }


        /* const productRef = doc(db, 'products', this.formData.product.toString());

         const productSnap = await getDoc(productRef);
         const product = productSnap.exists() ? productSnap.data(): {};*/


        const giveAwayCodesRef = collection(db, 'giveaways/' + this.formData.giveAway.toString() + '/codes');
        const giveAwayCodesQuery = query(giveAwayCodesRef, where("code", "==", this.formData.giveAwayCode));
        const giveAwayCodesSnap = await getDocs(giveAwayCodesQuery);

        const giveAwayUsersRef = collection(db, 'giveaways/' + this.formData.giveAway.toString() + '/users');
        const giveAwayUsersQuery = query(giveAwayUsersRef, where("password", "==", this.formData.giveAwayCode));
        const giveAwayUsersSnap = await getDocs(giveAwayUsersQuery);
        let giveAwayUsersDoc;

        if (!giveAwayUsersSnap.empty) {
          giveAwayUsersDoc = giveAwayUsersSnap.docs[0];
        }


        this.giveAwayCodes = [];
        for (const giveAwayDoc of giveAwayCodesSnap.docs) {
          const code = giveAwayDoc.data();
          this.giveAwayCodes.push({
            giveAwayUserDocId: giveAwayUsersDoc?.ref?.id,
            ...code
          });
        }

        if (this.giveAwayCodes.length === 0) {
          this.messageType = "codeNotFound";
        }
        /*if(this.giveAwayCodes.some(v=>v.userId !== null)) {
          this.messageType = "codeNotFound";
        }*/
        this.hideLoading();
      },

      splitDate(dateVal){
        if(dateVal != null){
          return dateVal.split(" ")[0];
        }else{
          return null;
        }
      }
    },

}
</script>

