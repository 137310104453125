<template>
    <div class="row mt-3">
        <div class="col-6">
            <h3>{{ $t('dashboard') }}</h3>
        </div>
        <div class="col-12">
            <hr/>
        </div>
    </div>
    <div class="row">
        <div class="col-6">

            <LineChart :data="data" :options="options" v-if="data.datasets[0].data.length>0"></LineChart>
        </div>
        <div class="col-6">

            <PieChart :data="pieData" :options="options" v-if="pieData.labels.length>0"></PieChart>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <hr/>
        </div>
        <div class="col-md-12">
            <div class="badge badge-secondary"><span class="text-secondary">{{ $t('giftRequestList') }}</span></div>
            <table class="table">
            <thead>
                <tr>
                    <th scope="col">{{ $t('title') }}</th>
                    <th scope="col">{{ $t('displayName') }}</th>
                    <th scope="col">{{ $t('description') }}</th>
                    <th scope="col">{{ $t('selectedCategory') }}</th>
                    <th scope="col">{{ $t('selectedProduct') }}</th>
                    <th scope="col">{{ $t('city') }}</th>
                    <th scope="col">{{ $t('district') }}</th>
                    <th scope="col">{{ $t('address') }}</th>
                    <th scope="col">{{ $t('createdDate') }}</th>
                </tr>
            </thead>
            <tbody>
                <tr :class="!notify.isRead ? 'table-success cursor-hand': 'cursor-hand'" v-for="notify in webNotifications" :key="notify['id']" @click="readWebNotification(notify['id'],notify['isRead'])">
                    <td>{{ notify.title }}</td>
                    <td>{{ notify.displayName }}</td>
                    <td>{{ notify.description }}</td>
                    <td>{{ notify.selectedCategory }}</td>
                    <td>{{ notify.selectedProduct }}</td>
                    <td>{{ notify.city }}</td>
                    <td>{{ notify.district }}</td>
                    <td>{{ notify.address }}</td>
                    <td>{{ notify.createdDate }}</td>
                </tr>
            </tbody>
            </table>
        </div>
    </div>
</template>
<script>

import { query, collection, getDocs, orderBy, doc, updateDoc,where } from "firebase/firestore";
import { getFirestore } from "firebase/firestore";
import db from '../firebase/init';
import { format } from 'date-fns';
import LineChart from '../components/LineChart.vue';
import PieChart from '../components/PieChart.vue';

export default {
    components: {
        LineChart,
        PieChart
    },
    data() {
        return {
            data: {
                labels: [this.$t('january'), this.$t('february'), this.$t('march'), this.$t('april'), this.$t('may'), this.$t('june'), this.$t('july'), this.$t('august'), this.$t('september'), this.$t('october'), this.$t('november'), this.$t('december')],
                datasets: [
                    {
                        label: this.$t('annualSalesReport'),
                        backgroundColor: '#209a52',
                        data: []
                    }
                ]
            },
            pieData: {
                labels: [],
                datasets: [
                    {
                        backgroundColor: [],
                        data: []
                    }
                ]
            },
            options: {
                responsive: true,
                maintainAspectRatio: false
            },
            webNotifications:[]
        };
    },
    async mounted() {
        await this.getWebNotifications();
        await this.getOrders();
    },
    methods:{
        async getOrders(){
            let ordersData = [];
            let productSales = {};
            const querySnap = await getDocs(query(collection(db, 'orders'), where("transactionType","==","sale")));
            ordersData = querySnap.docs.map((doc) => {
                return {
                    createdDate: doc.data().createdDate,
                    productName: doc.data().productName
                };
            });

            const currentYear = new Date().getFullYear();
            const monthlySales = Array(12).fill(0); // Ay bazında satışları toplamak için boş bir dizi oluşturun

            ordersData.forEach(sale => {
                let saleYear = null;
                let saleMonth = null;
                if(sale["createdDate"]!=null && sale["createdDate"]!=undefined){
                    saleYear = sale.createdDate.toDate().getFullYear();
                    saleMonth = sale.createdDate.toDate().getMonth();
                }else if(sale["vendorCreatedDate"]!=null && sale["vendorCreatedDate"]!=undefined){
                    saleYear = sale.vendorCreatedDate.toDate().getFullYear();
                    saleMonth = sale.vendorCreatedDate.toDate().getMonth();
                }


                if (saleYear === currentYear) {
                    monthlySales[saleMonth] += 1; // İlgili aydaki satış sayısını artırın
                }

                if (!productSales[sale.productName]) {
                    productSales[sale.productName] = 1;
                } else {
                    productSales[sale.productName]++;
                }
            });
            this.data.datasets[0].data = monthlySales;

            this.pieData.labels = Object.keys(productSales);
            this.pieData.datasets[0].data = Object.values(productSales);
            this.pieData.datasets[0].backgroundColor = Array(12).fill('#209a52');
            /*this.pieData.datasets[0].data.forEach(val => {
                this.pieData.datasets[0].backgroundColor.push(this.generateHexColorCode())
            })*/

        },
        generateHexColorCode() {
            const minRedValue = 0;
            const maxRedValue = 100;

            const minBlueValue = 0;
            const maxBlueValue = 100;

            const minGreenValue = 50;
            const maxGreenValue = 200;

            const randomRed = Math.floor(Math.random() * (maxRedValue - minRedValue + 1)) + minRedValue;
            const randomBlue = Math.floor(Math.random() * (maxBlueValue - minBlueValue + 1)) + minBlueValue;
            const randomGreen = Math.floor(Math.random() * (maxGreenValue - minGreenValue + 1)) + minGreenValue;

            const redHex = randomRed.toString(16).padStart(2, '0');
            const blueHex = randomBlue.toString(16).padStart(2, '0');
            const greenHex = randomGreen.toString(16).padStart(2, '0');

            return `#${redHex}${greenHex}${blueHex}`;
        },
        async getWebNotifications() {

            const querySnap = await getDocs(query(collection(db, 'webNotifications'), orderBy('createdDate', 'desc')));
            this.webNotifications = querySnap.docs.map((doc) => {
            // console.log(doc);
                return {
                    id: doc.id,
                    title: doc.data().title,
                    displayName: doc.data().displayName,
                    description: doc.data().description,
                    selectedCategory: doc.data().selectedCategory,
                    selectedProduct: doc.data().selectedProduct,
                    city: doc.data().city,
                    district: doc.data().district,
                    address: doc.data().address,
                    createdDate: format(doc.data().createdDate.toDate(), 'dd-MM-yyyy HH:mm'),
                    isRead: doc.data().isRead
                };
            });
        },
        async readWebNotification(id,isRead){
            const documentRef = doc(getFirestore(), 'webNotifications', id);
            await updateDoc(documentRef, { isRead: !isRead });
            this.getWebNotifications();
        }
    }
}
</script>

<style scoped>
.cursor-hand:hover{
    cursor: pointer;
}
</style>
