<template>
  <div class="row mt-3 vld-parent">
  <loading :active="isLoading"
           :can-cancel="true"
           :is-full-page="fullPage" >

  </loading>
    <div class="col-6">
<!--      <a href="#/" class="btn btn-sm btn-light"><i class="fa fa-chevron-left"></i> {{ $t('backTo') }}</a>-->
      <h3>{{ $t('checkProductStatus') }}</h3>
    </div>
    <div class="col-12">
      <hr/>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <div class="row">
        <div class="col-6 mb-3">
          <label for="productId" class="form-label">{{ $t('product') }}</label>
          <Select2 id="productId" v-model="formData.product" :options="productList" >
            <option v-for="product in products" :value="product['id']" :key="product['id']">{{ product['name'] }}</option>
          </Select2>
        </div>
        <div class="col-2 mb-3">
          <label for=qrPn class="form-label">{{ $t('qrPn') }}</label>
          <input type="text" id="qrPn" v-model="formData.qrCode.pn" class="form-control" required>
        </div>
        <div class="col-2 mb-3">
          <label for=qrSn class="form-label">{{ $t('qrSn') }}</label>
          <input type="text" id="qrSn" v-model="formData.qrCode.sn" class="form-control" required>
        </div>
        <div class="col-2 mb-3">
          <label for=qrPn class="form-label">&nbsp;</label>
          <button class="btn btn-sm btn-success form-control my-1" @click="checkProductStatus">{{ $t('query') }}</button>
        </div>
      </div>
    </div>
  </div>
<div>
  <div class="row" v-if="this.messageType === 'fillAllQuery'">
    <div class="col">
      {{$t("fillAllQuery")}}
    </div>
  </div>
  <div class="row" v-if="this.messageType === 'qrNotFound'">
    <div class="col">
      {{$t("qrNotFound")}}
    </div>
  </div>
  <div id="productList">
    <div v-for="item in this.qrCodes" v-bind:key="item.productId">
      <div class="card">
        <div class="card-header">
          <div class="d-flex">
            <div class="flex-fill">
              {{item.product.categoryName}} - <strong>{{item.product.name}}</strong> - {{ item.product.id}}
              {{ item.pn }} {{ item.sn }} {{  splitDate(item.skt) }}
            </div>
            <div>
              <div class="px-3 py-1 rounded-3" :class="{'text-bg-success' : item.product.active, 'text-bg-danger' : !item.product.active}">
                <span v-if="item.product.active">Aktif</span>
                <span v-else>Pasif</span>
              </div>
            </div>
          </div>
        </div>
        <div class="card-body">
<!--          <h5 class="card-title">{{item.id}}</h5>-->
<!--          <p class="card-text">With supporting text below as a natural lead-in to additional content.</p>-->
          <div class="row">
            <div class="col">
              <ul id="productList">
                <li v-for="(order, index) in item.orders" v-bind:key="index">
                  <div class="row">
                    <div class="col"> <h5 class="card-title">{{item.id}}</h5></div>
                  </div>
                  <div class="row">
                    <div class="col"><strong>{{ order.transactionType }}</strong></div>
                  </div>
                  <div class="row">
                    <div class="col"><strong>Satıcı</strong></div>
                  </div>
                  <div class="row">
                    <div class="col">{{ order.vendorCustomCode }}</div>
                    <div class="col">{{ order.vendorName }}</div>
                    <div class="col">{{ order.vendorStoreName }}</div>
                    <div class="col">{{ order.vendorPointAmount }}</div>
                    <div class="col">{{ formatDate(order.vendorCreatedDate?.seconds) }}</div>
                  </div>

                  <div class="row">
                    <div class="col"><strong>Müşteri</strong></div>
                  </div>
                  <div class="row">
                    <div class="col">{{ order.customerName }}</div>
                    <div class="col">{{ order.customerPointAmount }}</div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

<!--      <div class="row">
        <div class="col">
          {{item.product.categoryName}} - <strong>{{item.product.name}}</strong> - {{ item.product.id}}
          <strong>
            <span v-if="item.product.active">Aktif</span>
            <span v-else>Pasif</span>
          </strong>
        </div>
      </div>-->

<!--      <div class="row">
        <div class="col">{{ item.gtin }}</div>
        <div class="col">{{ item.pn }}</div>
        <div class="col">{{ item.sn }}</div>
        <div class="col">{{ item.skt }}</div>

      </div>-->

    </div>
  </div>
</div>

</template>
<script>

import '../assests/app.css'
import { ref } from 'vue';
// Import component
import Loading from 'vue3-loading-overlay';
// Import stylesheet
import 'vue3-loading-overlay/dist/vue3-loading-overlay.css';
import { query, collection, getDocs, getDoc, doc,  where } from "firebase/firestore";
import db from '../firebase/init';
import Select2 from 'vue3-select2-component'


export default {
    components: {
      Loading,
      Select2
    },
    data(){
        return{
            items: [],

          formData: {
            product: "",
            qrCode: {
              gtin: null,
              pn: "",
              sn: "",
            }
          },
          products: [],
          productList: [],
          qrCodes: [],
          messageType: null,

          isLoading: ref(false),
          fullPage: ref(true),
        }
    },
    async mounted() {

        // this.getVendors();
      await this.getProducts().then(()=>{
        this.products.forEach(item => {
            this.productList.push({
              id: item.id,
              text: item.name  + ' (' + item.id + ')',
            });
        });
      });
    },
    methods: {
      showLoading() {
        this.isLoading = true;
        setTimeout(() => {
          this.isLoading = false
        },60000)
      },
      hideLoading() {
        this.isLoading = false;
      },
        /*async getVendors() {
            const querySnap = await getDocs(query(collection(db, 'users'), where('role','==','vendor')));
            this.items = querySnap.docs.map((doc) => {
                return {
                    id: doc.id,
                    displayName: doc.data().displayName,
                    storeName: doc.data().storeName,
                    phoneNumber: doc.data().phoneNumber,
                    totalOrderCount: doc.data().totalOrderCount,
                    status: !doc.data().blocked ? this.$t('active') : this.$t('pasive'),
                    totalPoints: doc.data().balances.totalPoints,
                    loadedPoints: doc.data().balances.loadedPoints,
                    pendingPoints: doc.data().balances.pendingPoints,
                };
            });
        },*/
        formatDate(dateString) {
          const date =  new Date(dateString * 1000);
          return new Intl.DateTimeFormat('default', {dateStyle: 'long', timeStyle: 'short',}).format(date);
        },

      async getProducts() {

        const querySnap = await getDocs(query(collection(db, 'products'), where("active","==", true)));
        this.products = querySnap.docs.map((doc) => {
          return {
            id: doc.id,
            name: doc.data().name,
            categoryId: doc.data().categoryId,
            categoryName: doc.data().categoryName,
          }
        })
      },
      async checkProductStatus() {
        this.showLoading();
        this.messageType = null;
        if(this.formData.product == null || this.formData.qrCode.pn == null || this.formData.qrCode.sn == null || this.formData.product === "" || this.formData.qrCode.pn === "" || this.formData.qrCode.sn === "" ) {
          this.messageType = "fillAllQuery";
          return;
        }
        const productRef = doc(db, 'products', this.formData.product.toString());

        const productSnap = await getDoc(productRef);
        const product = productSnap.exists() ? productSnap.data(): {};


        const qrCodeRef = collection(db, 'products/'+this.formData.product.toString()+'/qrCodes');
        const qrCodeQuery = query(qrCodeRef, where("pn", "==", this.formData.qrCode.pn), where("sn", "==", this.formData.qrCode.sn) );
        const qrCodeSnap = await getDocs(qrCodeQuery);

        this.qrCodes = [];
        for (const qrCodeDoc of qrCodeSnap.docs) {
          const qrCode = qrCodeDoc.data();
          const orderRef = collection(db, 'orders');
          const orderQuery = query(orderRef, where("qrCode", "==", qrCodeDoc.id));
          const orderSnap = await getDocs(orderQuery);
          const orders = [];
          for (const orderDoc of orderSnap.docs) {
            orders.push(orderDoc.data());
          }
          qrCode['orders'] = orders;
          this.qrCodes.push({
            product: product,
            id:qrCodeDoc.id,
            ...qrCode
          });
        }
        if(this.qrCodes.length === 0) {
          this.messageType = "qrNotFound";
        }
        this.hideLoading();
      },
      splitDate(dateVal){
        if(dateVal != null){
          return dateVal.split(" ")[0];
        }else{
          return null;
        }
      }
    },

}
</script>

