<template>
    <div class="row mt-3">
        <div class="col-6">
            <h3>{{ $t('sliders') }}</h3>
        </div>
        <div class="col-6 text-end">
            <a href="#/slider/0" class="btn btn-sm btn-success"><i class="fa fa-add"></i> {{$t('addNewSlider')}}</a>
            <a href="javascript:;" class="btn btn-sm btn-secondary" style="margin-left: 15px;" @click="$exportExcel(colums, items, $t('sliders'))"><i class="fa fa-download"></i> {{$t('exportExcel')}}</a>
        </div>
        <div class="col-12">
            <hr/>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            
            <CustomDatatable v-if="items.length>0"
            :colums="colums"
            idColumn="id"
            :showDeleteButton="true"
            :showEditButton="true"
            :items="items"
            :editButtonMethod="editData"
            :deleteButtonMethod="deleteData"
            ></CustomDatatable>
        </div>
    </div>
</template>
<script>

import { query, collection, getDocs, doc, deleteDoc } from "firebase/firestore";
import { getFirestore } from "firebase/firestore";
import db from '../firebase/init';
import Swal from 'sweetalert2';
import CustomDatatable from '@/components/CustomDatatable.vue';


export default {
    components: {
        CustomDatatable
    },
    data(){
        return{
            colums:[
                { key: "orderNo", value: this.$t('orderNo')},
                { key: "description", value: this.$t('description')},
                { key: "languageCode", value: this.$t('languageCode')},
                { key: "status", value: this.$t('status')},
            ],
            items: []
        }
    },
    mounted() {
        this.getList();
    },  
    methods: {
        async getList() {

            const querySnap = await getDocs(query(collection(db, 'sliders')));
            this.items = querySnap.docs.map((doc) => {
                return {
                    id: doc.id,
                    orderNo: doc.data().orderNo,
                    description: doc.data().description,
                    languageCode: this.$t(doc.data().languageCode),
                    status: doc.data().active ? this.$t('active') : this.$t('pasive'),
                };
            });
        },
        editData(_id){
            this.$router.push({ name: 'slider', params: { id: _id } });
        },
        deleteData(_id) {
            Swal.fire({
                title: this.$t('areYouSure'),
                text: this.$t('areYouSureDelete'),
                icon: 'question',
                showCancelButton: true,
                confirmButtonColor: '#0f7438',
                cancelButtonColor: '#6c757d',
                confirmButtonText: this.$t('delete'),
                cancelButtonText: this.$t('cancel')
            }).then(async (result) => {
                if (result.isConfirmed) {
                    const documentRef = doc(getFirestore(), 'sliders', _id);
                    try {
                        await deleteDoc(documentRef);                       
                        Swal.fire({
                            title: this.$t('success'),
                            text: this.$t('deleteSuccessful'),
                            icon: 'success',
                            confirmButtonText: this.$t('ok'),
                            confirmButtonColor: '#0f7438',
                        });
                        this.items = [];
                        await this.getList();
                    } catch (error) {
                        Swal.fire({
                            title: this.$t('error'),
                            text: error,
                            icon: 'error',
                            confirmButtonText: this.$t('ok')
                        });
                    }
                }
            });
        },
    },

}
</script>

