<template>
    <div class="row mt-3">
        <div class="col-6">
            <h3>{{ $t('vendors') }}</h3>
        </div>
        <div class="col-6 text-end">
            <a href="#/vendor/0" class="btn btn-sm btn-success"><i class="fa fa-add"></i> {{$t('addNewVendor')}}</a>
            <a href="#/vendor-bulk-insert" class="btn btn-sm btn-success" style="margin-left: 15px;"><i class="fa fa-upload"></i> {{$t('bulkInsertVendor')}}</a>
            <a href="javascript:;" class="btn btn-sm btn-secondary" style="margin-left: 15px;" @click="$exportExcel(colums, items, $t('vendors'))"><i class="fa fa-download"></i> {{$t('exportExcel')}}</a>
        </div>
        <div class="col-12">
            <hr/>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            
            <CustomDatatable v-if="items.length>0"
            :colums="colums"
            idColumn="id"
            :showDeleteButton="true"
            :showEditButton="true"
            :items="items"
            :editButtonMethod="editData"
            :deleteButtonMethod="deleteData"
            ></CustomDatatable>
        </div>
    </div>
</template>
<script>

import { query, collection, getDocs, doc, deleteDoc, where } from "firebase/firestore";
import { getFirestore } from "firebase/firestore";
import db from '../firebase/init';
import { getFunctions, httpsCallable } from "firebase/functions";
import Swal from 'sweetalert2';
import CustomDatatable from '@/components/CustomDatatable.vue';


export default {
    components: {
        CustomDatatable
    },
    data(){
        return{
            colums:[
                { key: "displayName", value: this.$t('nameSurname')},
                { key: "storeName", value: this.$t('storeName')},
                { key: "phoneNumber", value: this.$t('phoneNumber')},
                { key: "totalOrderCount", value: this.$t('totalOrderCount')},
                { key: "status", value: this.$t('status')},
                { key: "totalPoints", value: this.$t('totalPoints')},
                { key: "loadedPoints", value: this.$t('loadedPoints')},
                { key: "pendingPoints", value: this.$t('pendingPoints')},
            ],
            items: []
        }
    },
    mounted() {
        this.getVendors();
    },  
    methods: {
        async getVendors() {

            const querySnap = await getDocs(query(collection(db, 'users'), where('role','==','vendor')));
            this.items = querySnap.docs.map((doc) => {
                return {
                    id: doc.id,
                    displayName: doc.data().displayName,
                    storeName: doc.data().storeName,
                    phoneNumber: doc.data().phoneNumber,
                    totalOrderCount: doc.data().totalOrderCount,
                    status: !doc.data().blocked ? this.$t('active') : this.$t('pasive'),
                    totalPoints: doc.data().balances.totalPoints,
                    loadedPoints: doc.data().balances.loadedPoints,
                    pendingPoints: doc.data().balances.pendingPoints,
                };
            });
        },
        editData(_id){
            this.$router.push({ name: 'vendor', params: { id: _id } });
        },
        deleteData(_id) {
            Swal.fire({
                title: this.$t('areYouSure'),
                text: this.$t('areYouSureDelete'),
                icon: 'question',
                showCancelButton: true,
                confirmButtonColor: '#0f7438',
                cancelButtonColor: '#6c757d',
                confirmButtonText: this.$t('delete'),
                cancelButtonText: this.$t('cancel')
            }).then(async (result) => {
                if (result.isConfirmed) {
                    const functions = getFunctions();
                    httpsCallable(functions,"deleteUser")({userId: _id}).then(async result => {
                        if(result.data.success==true){
                            this.items = [];
                            await this.getVendors();

                            Swal.fire({
                                title: this.$t('success'),
                                text: this.$t('deleteSuccessful'),
                                icon: 'success',
                                confirmButtonText: this.$t('ok'),
                                confirmButtonColor: '#0f7438',
                            });
                        }else{
                            console.log(result.data);
                            Swal.fire({
                                title: this.$t('error'),
                                text: result.data.message,
                                icon: 'error',
                                confirmButtonText: this.$t('ok')
                            });
                        }
                        
                    })
                    .catch(error => {
                        Swal.fire({
                            title: this.$t('error'),
                            text: error,
                            icon: 'error',
                            confirmButtonText: this.$t('ok')
                        });
                    });

                    /*const documentRef = doc(getFirestore(), 'stores', _id);
                    try {
                        await deleteDoc(documentRef);                       
                        Swal.fire({
                            title: this.$t('success'),
                            text: this.$t('deleteSuccessful'),
                            icon: 'success',
                            confirmButtonText: this.$t('ok'),
                            confirmButtonColor: '#0f7438',
                        });
                        this.items = [];
                        await this.getVendors();
                    } catch (error) {
                        Swal.fire({
                            title: this.$t('error'),
                            text: error,
                            icon: 'error',
                            confirmButtonText: this.$t('ok')
                        });
                    }*/
                }
            });
        },
    },

}
</script>

