<template>
    <div class="container login-container">
            <div class="row">
                <div class="col-md-6 login-form-1">
                    <h3>{{ $t('forgetPassword') }}</h3>
                    <form @submit.prevent="submitForm">
                        <div class="form-group">
                            <label for="txtEmail" class="form-label">{{ $t('yourEmail') }}:</label>
                            <input type="email" class="form-control" v-model="formData.email" id="txtEmail" />
                        </div>
                        <div class="form-group">
                            <div class="row">
                                <div class="col-md-8 text-start">
                                    <input type="submit" class="btnSubmit" :value="$t('sendEmail')" />
                                </div>
                                <div class="col-md-4 text-end pt-1">
                                    <a href="/" class="ForgetPwd">{{ $t('backTo') }}</a>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        
</template>
<script>

import {
  getAuth,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  // onAuthStateChanged,
  // updateProfile,
  // updateEmail,
  // updatePassword,
  // reauthenticateWithCredential,
  // signOut,  
} from "firebase/auth";
import Swal from 'sweetalert2';

export default {
    components: {
        
    },
    data() {
        return {
            formData: {
                email: '',
            }
        }
    },
    methods: {
        async submitForm() {
            if(this.formData.email == ''){
                Swal.fire({
                    title: this.$t('warning'),
                    text: this.$t('leaseEnterEmail'),
                    icon: 'warning',
                    confirmButtonText: this.$t('ok')
                });
            }else{
                const email = this.formData.email;

                var mailPattern = new RegExp("^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$");
                if((!mailPattern.test(email))){
                    Swal.fire({
                        title: this.$t('warning'),
                        text: this.$t('invalidEmail'),
                        icon: 'warning',
                        confirmButtonText: this.$t('ok')
                    });
                }else{

                    const auth = getAuth();

                    sendPasswordResetEmail(auth, email)
                    .then(() => {
                        Swal.fire({
                            title: this.$t('success'),
                            text: this.$t('sendEmailSuccessfullyMessage'),
                            icon: "success",
                            confirmButtonText: this.$t('ok')
                        });
                    })
                    .catch((error) => {
                        var errorCode = error.code;
                        var errorMessage = error.message;
                        console.log(errorMessage);
                        Swal.fire({
                            title: this.$t('error'),
                            text: this.$t('sendEmailFailedMessage'),
                            icon: "error",
                            confirmButtonText: this.$t('ok')
                        });
                    });

                }

            }
        
        },
    }
}
</script>

<style scoped>
.form-group{
    margin-top: 10px;
}
.login-container{
    margin-top: 5%;
    margin-bottom: 5%;
}
.login-form-1{
    margin-right: auto;
    margin-left: auto;
    padding: 5%;
    box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.2), 0 9px 26px 0 rgba(0, 0, 0, 0.19);
}
.login-form-1 h3{
    text-align: center;
    color: #333;
}
.login-form-2{
    padding: 5%;
    background: #287748;
    box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.2), 0 9px 26px 0 rgba(0, 0, 0, 0.19);
}
.login-form-2 h3{
    text-align: center;
    color: #fff;
}
.login-container form{
    padding: 10%;
}
.btnSubmit
{
    width: 100%;
    border-radius: 1rem;
    border: none;
    cursor: pointer;
}
.login-form-1 .btnSubmit{
    font-weight: 600;
    color: #fff;
    background-color: #287748;
    padding: 5px;
}
.login-form-2 .btnSubmit{
    font-weight: 600;
    color: #287748;
    background-color: #fff;
}
.login-form-2 .ForgetPwd{
    color: #fff;
    font-weight: 600;
    text-decoration: none;
}
.login-form-1 .ForgetPwd{
    color: #287748;
    font-weight: 600;
    text-decoration: none;
}
</style>