import ExcelJS from 'exceljs';

const ExcelExportHelper = {
    install(app) {
      app.config.globalProperties.$exportExcel = async function (headings,rows,exportName) {
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Sheet 1');

        let titles = [];
        headings.forEach(heading => {
            titles.push(heading.value);
        });
        worksheet.addRow(titles);
        rows.forEach(row => {
            let line = [];
            headings.forEach(heading => {
                line.push(row[heading.key]);
            });
            worksheet.addRow(line);
        })

        const buffer = await workbook.xlsx.writeBuffer();
        const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = exportName+'.xlsx';
        link.click();
      };
    }
  };
  
  export default ExcelExportHelper;