<template>
    <div class="row mt-3">
        <div class="col-6">
            <h3>{{ $t('sendPushNotification') }}</h3>
        </div>
        <div class="col-12">
            <hr/>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6">
            <form @submit.prevent="submitForm">
            <div class="mb-3">
                <label for="title" class="form-label">{{ $t('title') }}</label>
                <input type="text" id="title" v-model="formData.title" class="form-control" required>
            </div>
            <div class="mb-3">
                <label for="description" class="form-label">{{ $t('description') }}</label>
                <input type="text" id="description" v-model="formData.description" class="form-control" required>
            </div>
            <div class="mb-3">
                <label for="userType" class="form-label">{{ $t('userType') }}</label>
                <select class="form-select" id="userType" v-model="formData.userType" @change="getUsers()">
                    <option value="">{{ $t('allUserTypes') }}</option>
                    <option value="vendor">{{ $t('vendors') }}</option>
                    <option value="customer">{{ $t('customers') }}</option>
                </select>
            </div>
            <div class="mb-3">
                <label for="userType" class="form-label">{{ $t('usersChoice') }} ({{ this.formData.selectedUsers.length }})</label>
                <v-select
                    v-model="formData.selectedUsers"
                    :options="formData.users"
                    label="name"
                    multiple
                />
                <button class="btn btn-sm btn-secondary" type="button" @click="selectAllItems" style="margin-top:5px;margin-left:5px;">{{ $t("allSelected") }}</button>
                <button class="btn btn-sm btn-secondary" type="button" @click="clearAllItems" style="margin-top:5px;margin-left:5px;">{{ $t("clearSelect") }}</button>
            </div>
            <button type="submit" class="btn btn-success">{{ $t('send') }}</button>
            </form>
        </div>
    </div>
</template>
<script>
import { collection, doc, getDoc, addDoc, setDoc, updateDoc, serverTimestamp, deleteDoc, query, where, getDocs  } from "firebase/firestore";
import { getFirestore } from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";
import db from '../firebase/init';
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';

import Swal from 'sweetalert2';

export default {
    components: {
        vSelect
    },
    data(){
        return {
            formData: {
                userType: '',
                users: [{value:"0",text:"asd"}],
                selectedUsers: [],
                title: '',
                description: ''
            },
        }
    },
    async mounted() {
        await this.getUsers();
    },  
    methods: {
        async getUsers() {
            
            if(this.formData.userType==''){
                const querySnap = await getDocs(query(collection(db, 'users'), where('deviceId','!=','')));;
                this.formData.users = querySnap.docs.map((doc) => {
                    return {
                        id: doc.data().deviceId,
                        name: doc.data().displayName+' ('+doc.data().phoneNumber+')',
                    };
                });
            }
            else if(this.formData.userType=='vendor'){
                const querySnap = await getDocs(query(collection(db, 'users'), where('role','==','vendor'), where('deviceId','!=','')));
                this.formData.users = querySnap.docs.map((doc) => {
                    return {
                        id: doc.data().deviceId,
                        name: doc.data().displayName+' ('+doc.data().phoneNumber+')',
                    };
                });
            }else if(this.formData.userType=='customer'){
                const querySnap = await getDocs(query(collection(db, 'users'), where('role','==','customer'), where('deviceId','!=','')));
                this.formData.users = querySnap.docs.map((doc) => {
                    return {
                        id: doc.data().deviceId,
                        name: doc.data().displayName+' ('+doc.data().phoneNumber+')',
                    };
                });
            }

            
        },
        async submitForm() {
            if(this.formData.title=="" || this.formData.description==""){
                Swal.fire({
                    title: this.$t('warning'),
                    text: this.$t('pleaseEnterTitle'),
                    icon: 'warning',
                    confirmButtonText: this.$t('ok')
                });
                return;
            } else if(this.formData.selectedUsers.length==0){
                Swal.fire({
                    title: this.$t('warning'),
                    text: this.$t('pleaseSelectUser'),
                    icon: 'warning',
                    confirmButtonText: this.$t('ok')
                });
                return;
            } else if(this.formData.selectedUsers.length>500){
                Swal.fire({
                    title: this.$t('warning'),
                    text: this.$t('maxSelectUserLimit'),
                    icon: 'warning',
                    confirmButtonText: this.$t('ok')
                });
                return;
            }

            let selectedDeviceIds = this.formData.selectedUsers.map((item) => (item.id));

            this.$setLoading(true);
            const functions = getFunctions();
            httpsCallable(functions,"sendRawNotification")({userDeviceIds: selectedDeviceIds, title: this.formData.title, body: this.formData.description, imageUrl: null, customData: "{\"name\":\"bar\"}"}).then(async result => {
                this.$setLoading(false);
                if(result.data.successCount>0){
                    Swal.fire({
                        title: this.$t('success'),
                        text: this.$t('pushNotificationSent')+ ' '+this.$t('successCount')+':'+result.data.successCount+', '+this.$t('failureCount')+':'+result.data.failureCount,
                        icon: 'success',
                        confirmButtonText: this.$t('ok'),
                        confirmButtonColor: '#0f7438',
                    });
                }else{
                    Swal.fire({
                        title: this.$t('error'),
                        text: result.data.message+ ' '+this.$t('successCount')+':'+result.data.successCount+', '+this.$t('failureCount')+':'+result.data.failureCount,
                        icon: 'error',
                        confirmButtonText: this.$t('ok')
                    });
                }
            })
            .catch(error => {
                this.$setLoading(false);
                Swal.fire({
                    title: this.$t('error'),
                    text: error,
                    icon: 'error',
                    confirmButtonText: this.$t('ok')
                });
            });

        },
        selectAllItems(){
            this.formData.selectedUsers = this.formData.users.map((item) => ({
                id: item.id,
                name: item.name,
            }));
        },
        clearAllItems(){
            this.formData.selectedUsers = [];
        }
    },

}
</script>