<template>
    <div class="row mt-3">
        <div class="col-6">
            <a href="#/products" class="btn btn-sm btn-light"><i class="fa fa-chevron-left"></i> {{ $t('backTo') }}</a>
            <h3>{{ $route.params.id=='0' ? $t('addNewProduct') : $t('editProduct') }}</h3>
        </div>
        <div class="col-12">
            <hr/>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6">
            <form @submit.prevent="submitForm">
            <div class="mb-3">
                <label for="language" class="form-label">{{ $t('language') }}</label>
                <select class="form-select" id="language" v-model="formData.languageCode" @change="changeLanguageOrType">
                    <option value="tr">{{ $t('tr') }}</option>
                    <option value="en">{{ $t('en') }}</option>
                    <option value="de">{{ $t('de') }}</option>
                </select>
            </div>
            <div class="mb-3">
                <label for="categoryType" class="form-label">{{ $t('categoryType') }}</label>
                <select class="form-select" id="categoryType" v-model="formData.categoryType" @change="changeLanguageOrType">
                    <option value="veganic">veganic</option>
                    <option value="collection">collection</option>
                    <option value="hush">hush</option>
                </select>
            </div>
            <div class="mb-3">
                <label for="categoryId" class="form-label">{{ $t('category') }}</label>
                <select class="form-select" id="categoryId" v-model="formData.categoryId">
                    <option v-for="category in categories" :value="category['id']" :key="category['id']">{{ category['name'] }}</option>
                </select>
            </div>
            <div class="mb-3">
                <label for="id" class="form-label">{{ $t('productBarcode') }}</label>
                <input type="text" id="id" v-model="formData.id" class="form-control" :readonly="$route.params.id!='0'" required>
            </div>
            <div class="mb-3">
                <label for="orderNo" class="form-label">{{ $t('orderNo') }}</label>
                <input type="number" id="orderNo" v-model="formData.orderNo" class="form-control" required>
            </div>
            <div class="mb-3">
                <label for="name" class="form-label">{{ $t('name') }}</label>
                <input type="text" id="name" v-model="formData.name" class="form-control" required>
            </div>
            <div class="mb-3">
                <label for="name" class="form-label"  style="margin-right: 20px;">{{ $t('fontColor') }}</label>
                <color-picker v-model:pureColor="formData.panelDescription.fontColor"/>
            </div>
            <!-- <div class="mb-3">
                <label for="smallImage" class="form-label">{{ $t('smallImage') }}</label>
                <input type="file" @change="handleFileChangeSmallImage">
                <button @click="uploadImageSmallImage" :disabled="!selectedSmallImage" class="btn btn-sm btn-success">{{ $t('uploadSmallImage') }}</button>
                <div v-if="formData.panelDescription.smallImage!=''">
                    <img :src="formData.panelDescription.smallImage" style="height: 50px;">
                </div>
            </div>
            <div class="mb-3">
                <label for="smallImageText" class="form-label">{{ $t('smallImageText') }}</label>
                <input type="text" id="smallImageText" v-model="formData.panelDescription.smallImageText" class="form-control">
            </div>
            <div class="mb-3">
                <label for="smallImage" class="form-label">{{ $t('smallImage') }}(2)</label>
                <input type="file" @change="handleFileChangeSmallImage2">
                <button @click="uploadImageSmallImage2" :disabled="!selectedSmallImage2" class="btn btn-sm btn-success">{{ $t('uploadSmallImage') }}(2)</button>
                <div v-if="formData.panelDescription.smallImage2!=''">
                    <img :src="formData.panelDescription.smallImage2" style="height: 50px;">
                </div>
            </div>
            <div class="mb-3">
                <label for="smallImageText2" class="form-label">{{ $t('smallImageText') }}(2)</label>
                <input type="text" id="smallImageText2" v-model="formData.panelDescription.smallImageText2" class="form-control">
            </div> -->
            <div class="mb-3">
                <label for="description" class="form-label">{{ $t('description') }}</label>
                <ckeditor :editor="editor" v-model="formData.panelDescription.description" :config="editorConfig"></ckeditor>
            </div>
            <div class="mb-3">
                <label for="detail" class="form-label">{{ $t('productStoreDetail') }}</label>
                <ckeditor :editor="editor" v-model="formData.detail" :config="editorConfig"></ckeditor>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <div class="mb-3">
                        <label for="vendorPoint" class="form-check-label">{{ $t('vendorEarnPoint') }}</label>
                        <input type="number" id="vendorPoint" v-model="formData.vendorPoint" class="form-control text-end" inputmode="numeric">
                    </div>
                </div>
                <div class="col-md-6" v-if="false">
                    <div class="mb-3">
                        <label for="customerPoint" class="form-check-label">{{ $t('customerEarnPoint') }}</label>
                        <input type="number" id="customerPoint" v-model="formData.customerPoint" class="form-control text-end" inputmode="numeric" readonly>
                    </div>
                </div>
            </div>
            <div class="mb-3">
                <label for="active" class="form-check-label">{{ $t('isActive') }}</label>
                <input type="checkbox" id="active" v-model="formData.active" class="form-check-input">
            </div>
            <button type="submit" class="btn btn-success">{{ $t('save') }}</button>
            </form>
        </div>
        <div class="col-md-6">
            <div class="row">
                <div class="col-12 mb-3">
                    <span class="badge bg-secondary">{{ $t('productImageHelpText') }}</span>
                </div>
                <div class="col-12 mb-3">
                    <input type="file" @change="handleFileChange">
                    <button @click="uploadImage" :disabled="!selectedImage" class="btn btn-sm btn-success">{{ $t('uploadImage') }}</button>
                    <div v-if="formData.imageUrl!=''">
                        <img :src="formData.imageUrl" style="width: 50%;">
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>
<script>

import { collection, doc, getDoc, addDoc, setDoc, updateDoc, serverTimestamp, query, where, getDocs  } from "firebase/firestore";
import { getFirestore } from "firebase/firestore";
import { getStorage, uploadBytes, ref as storageRef, getDownloadURL, deleteObject  } from 'firebase/storage';
import db from '../firebase/init';
import Swal from 'sweetalert2';

import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import { ColorPicker } from "vue3-colorpicker";
import "vue3-colorpicker/style.css";

export default {
    components: {
        ColorPicker
    },
    data(){
        return {
            formData: {
                id: '0',
                languageCode: 'tr',
                categoryType: 'veganic',
                categoryId: '',
                categoryName: '',
                orderNo: 0,
                name: '',
                imageUrl: '',
                description: '<p></p>',
                detail: '<p></p>',
                vendorPoint: 0,
                customerPoint: 0,
                panelDescription: {
                    fontColor: '#000000',
                    smallImage: '',
                    smallImageText: '',
                    smallImage2: '',
                    smallImageText2: '',
                    description: '',
                },
                active: true
            },
            editor: ClassicEditor,
            editorConfig: {
                //toolbar: ['undo', 'redo', '|', 'heading', '|', 'bold', 'italic', 'link', '|', 'fontColor'],
                /*toolbar: {
                    items: [
                        'bold',
                        'italic',
                        'link',
                        'fontColor', //fontColor işe yaramadı
                        'undo',
                        'redo',

                    ]
                },*/
                allowedContent: true
            },
            categories: [],
            selectedImage: null,
            selectedSmallImage: null,
            selectedSmallImage2: null
        }
    },
    async mounted() {
        const id = this.$route.params.id;
        await this.getCategories();
        if(id!='0'){
            const productData = await this.getProduct(id);

            if (productData) {
                this.formData = { ...productData };
                this.formData.id = id;
                await this.getCategories();

                if(productData.panelDescription==undefined || productData.panelDescription==null){
                    this.formData.panelDescription = {
                        fontColor: '#000000',
                        smallImage: '',
                        smallImageText: '',
                        smallImage2: '',
                        smallImageText2: '',
                        description: '',
                    };
                }else if (productData.panelDescription.fontColor==undefined || productData.panelDescription.fontColor==null){
                    this.formData.panelDescription.fontColor = '#000000';
                }
                //console.log(this.formData);
            }
        }
    },
    methods: {
        async getProduct(id) {

            const documentRef = doc(getFirestore(), 'products', id);
            const documentSnapshot = await getDoc(documentRef);

            if (documentSnapshot.exists()) {
                return {
                id: id,
                ...documentSnapshot.data()
                };
            }
            return null;
        },
        async getCategories() {
            if(this.formData.languageCode && this.formData.categoryType){
                const q = query(collection(db, 'categories'),
                    where('languageCode', '==', this.formData.languageCode),
                    where('categoryType', '==', this.formData.categoryType)
                );

                const querySnap = await getDocs(q);
                this.categories = querySnap.docs.map((doc) => {
                    return {
                        id: doc.id,
                        name: doc.data().name
                    };
                });

                if(this.formData.id=='0' && this.categories!=null && this.categories[0]!=null){
                    this.formData.categoryId = this.categories[0].id;
                }
            }else{
                this.categories = [];
            }

        },
        async changeLanguageOrType(){
            await this.getCategories();
        },
        handleFileChange(event) {
            this.selectedImage = event.target.files[0];
        },
        async uploadImage(){
            this.$setLoading(true);

            /*
            //Öncekini silme işlemi
            if(this.formData.imageUrl!='')
            {
                const storage = getStorage();
                const imageRef = storageRef(storage, this.formData.imageUrl);

                deleteObject(imageRef)
                .then(() => {
                    console.log('onceki silindi');
                })
                .catch((error) => {
                    console.error('onceki silinirken bir hata:', error);
                });
            }*/

            const randomNumber = this.generateRandomNumber();
            const storage = getStorage();
            const storageReference = storageRef(storage, 'product_'+randomNumber+'_'+this.selectedImage.name);

            try {
                await uploadBytes(storageReference, this.selectedImage);
                const url = await getDownloadURL(storageReference);
                this.formData.imageUrl = url;
                this.selectedImage = null;
                this.$setLoading(false);
            } catch (error) {
                this.$setLoading(false);
                Swal.fire({
                    title: this.$t('error'),
                    text: error,
                    icon: 'error',
                    confirmButtonText: this.$t('ok')
                });
            }
        },
        handleFileChangeSmallImage(event) {
            this.selectedSmallImage = event.target.files[0];
        },
        handleFileChangeSmallImage2(event) {
            this.selectedSmallImage2 = event.target.files[0];
        },
        async uploadImageSmallImage(){
            this.$setLoading(true);
            console.log("this.formData.selectedSmallImage",this.formData.selectedSmallImage);
            if(this.formData.selectedSmallImage!='' && this.formData.selectedSmallImage!=undefined)
            {
                const storage = getStorage();
                const imageRef = storageRef(storage, this.formData.selectedSmallImage);

                deleteObject(imageRef)
                .then(() => {
                    console.log('onceki silindi');
                })
                .catch((error) => {
                    console.error('onceki silinirken bir hata:', error);
                });
            }

            const randomNumber = this.generateRandomNumber();
            const storage = getStorage();
            const storageReference = storageRef(storage, 'product_small_'+randomNumber+'_'+this.selectedSmallImage.name);

            try {
                await uploadBytes(storageReference, this.selectedSmallImage);
                const url = await getDownloadURL(storageReference);
                this.formData.panelDescription.smallImage = url;
                this.selectedSmallImage = null;
                this.$setLoading(false);
            } catch (error) {
                this.$setLoading(false);
                Swal.fire({
                    title: this.$t('error'),
                    text: error,
                    icon: 'error',
                    confirmButtonText: this.$t('ok')
                });
            }
        },
        async uploadImageSmallImage2(){
            this.$setLoading(true);
            if(this.formData.selectedSmallImage2!='' && this.formData.selectedSmallImage2!=undefined)
            {
                const storage = getStorage();
                const imageRef = storageRef(storage, this.formData.selectedSmallImage2);

                deleteObject(imageRef)
                .then(() => {
                    console.log('onceki silindi');
                })
                .catch((error) => {
                    console.error('onceki silinirken bir hata:', error);
                });
            }

            const randomNumber = this.generateRandomNumber();
            const storage = getStorage();
            const storageReference = storageRef(storage, 'product_small_'+randomNumber+'_'+this.selectedSmallImage2.name);

            try {
                await uploadBytes(storageReference, this.selectedSmallImage2);
                const url = await getDownloadURL(storageReference);
                this.formData.panelDescription.smallImage2 = url;
                this.selectedSmallImage2 = null;
                this.$setLoading(false);
            } catch (error) {
                this.$setLoading(false);
                Swal.fire({
                    title: this.$t('error'),
                    text: error,
                    icon: 'error',
                    confirmButtonText: this.$t('ok')
                });
            }
        },
        generateRandomNumber() {
            const min = 100000;
            const max = 999999;
            return Math.floor(Math.random() * (max - min + 1)) + min;
        },
        generateHtmlDescription(){
            //let fontColor = this.getCategoryColorCode();
            let fontColor = this.formData.panelDescription.fontColor;
            return '<div style="width: 100%;color:'+fontColor+'">'+this.formData.panelDescription.description+'<div>';
            /*return '<div style="width: 100%;"> <div style="display: inline-block; width: 20%; box-sizing: border-box; padding: 10px; text-align: center; color: #005f86;" font-family: Arial;> <p> <img src="'+this.formData.panelDescription.smallImage+'" style="height: 200px;" /> <br>'+this.formData.panelDescription.smallImageText+'</p> <p> <img src="'+this.formData.panelDescription.smallImage2+'" style="height: 200px;" /> <br>'+this.formData.panelDescription.smallImageText2+' </p> </div> <div style="display: inline-block; width: 70%; margin-left: 5%; box-sizing: border-box; padding: 20px; border-radius: 20px; background-color: #f0f0f0; color: #005f86; font-family: Arial; vertical-align: top;"> '+this.formData.panelDescription.description+' </div> </div>';*/
        },
        generateHtmlDetail(){
            //let fontColor = this.getCategoryColorCode();
            let fontColor = this.formData.panelDescription.fontColor;
            return '<div style="width: 100%;color:'+fontColor+'">'+this.formData.detail+'<div>';
        },
        getCategoryColorCode(){
            let catId = this.formData.categoryId;
            if(catId=='7C7OXDbiKXn0oKqfUIGn'){//Veganic Collection
                return '#63656A';
            }else if(catId=='oQSeHV05cjlgOoR3ismg'){//The Passion Koleksiyon
                return '#63656A';
            }else if(catId=='GFLSAHsGnO09yok9DcFY'){//Oksidanlar
                return '#63656A';
            }else if(catId=='OJ9B3uDztlBlwlNpz0en'){//Mayday
                return '#63656A';
            }else if(catId=='PoMGPWEjo3em5UEYImFz'){//Hushmellow
                return '#63656A';
            }else if(catId=='X1WmGV89vyawHAjOE2oX'){//Tangle Free
                return '#63656A';
            }else if(catId=='kbe5JVmfIXxCr16DcvcC'){//Saç Boyası
                return '#63656A';
            }else if(catId=='nPwEFqztlmnw2nGQr4X2'){//Açıcı Ürünler
                return '#63656A';
            }else if(catId=='AeSauFBb9NBlVhL1yVLZ'){//Moisture Boost
                return '#005D5E';
            }else if(catId=='AeSauFBb9NBlVhL1yVLZ'){//Ultimate Repair
                return '#852D4E';
            }else if(catId=='AoSphkZ3dfiVmQrGAmKE'){//Root Recharge
                return '#63656A';
            }else if(catId=='BPMPmcUFvaW9HblX6DEd'){//Pure Comfort
                return '#63656A';
            }else if(catId=='Gh67qZ0ZMBBa8buUkmSK'){//Perfect Blonde
                return '#2A266D';
            }else if(catId=='ICOixSGrVvh4pmoRkdhb'){//Zero Flake
                return '#63656A';
            }else if(catId=='VGpDovNxYOrxGC8dBftw'){//Color Shield
                return '#894A34';
            }else if(catId=='VpRarGAiWdCZqFGJRPny'){//Glorious Volume
                return '#87364E';
            }else if(catId=='iVwzMxgKYf4zksqxmSA2'){//Brilliant Smooth
                return '#006746';
            }else if(catId=='ukGxK5ZHkA6wiDOhpHPi'){//Super Soft
                return '#006080';
            }else{
                return '#000000';
            }
        },
        setYoutubeControls(){
            const iframe = document.querySelector("iframe");
            if(iframe!=null && iframe!=undefined){
                let src = iframe.getAttribute("src");

                if (!src.includes("?control=0")) {
                    src += "?control=0";
                    iframe.setAttribute("src", src);
                }
            }

        },
        async submitForm() {
            const id = this.$route.params.id;

            if(this.formData.categoryId == '' || this.formData.categoryId == null || this.formData.categoryId == undefined){
                Swal.fire({
                    title: this.$t('warning'),
                    text: this.$t('pleaseChooseCategory'),
                    icon: 'warning',
                    confirmButtonText: this.$t('ok')
                });
            }else if(this.formData.imageUrl == '' || this.formData.imageUrl == null || this.formData.imageUrl == undefined){
                Swal.fire({
                    title: this.$t('warning'),
                    text: this.$t('pleaseUploadImage'),
                    icon: 'warning',
                    confirmButtonText: this.$t('ok')
                });
            }else if(this.formData.id == '' || this.formData.id == '0'){
                Swal.fire({
                    title: this.$t('warning'),
                    text: this.$t('pleaseEnterProductBarcode'),
                    icon: 'warning',
                    confirmButtonText: this.$t('ok')
                });
            }else{

                if(this.formData.panelDescription.fontColor == '' || this.formData.panelDescription.fontColor == null){
                    this.formData.panelDescription.fontColor = '#000000';
                }

                this.setYoutubeControls();

                const cat = this.categories.find(item => item.id === this.formData.categoryId);
                this.formData.categoryName = cat.name;

                this.formData.description = this.generateHtmlDescription();
                this.formData.detail = this.generateHtmlDetail();

                try {
                    if (id == '0') {
                        const documentRef = doc(getFirestore(), 'products', this.formData.id);
                        await setDoc(documentRef, { ...this.formData, createdDate: serverTimestamp() });
                        this.formData = {
                            id: '0',
                            languageCode: 'tr',
                            categoryType: 'veganic',
                            categoryId: '',
                            categoryName: '',
                            orderNo: 0,
                            name: '',
                            imageUrl: '',
                            description: '<p></p>',
                            detail: '<p></p>',
                            vendorPoint: 0,
                            customerPoint: 0,
                            panelDescription: {
                                fontColor: '#000000',
                                smallImage: '',
                                smallImageText: '',
                                smallImage2: '',
                                smallImageText2: '',
                                description: '',
                            },
                            active: true
                        };

                        //qrCodes subCollection add operation
                        //const qrCodesCollectionRef = collection(documentRef, 'qrCodes');
                        //await addDoc(qrCodesCollectionRef, { key: 'qrCodes' });

                    } else {
                        const documentRef = doc(getFirestore(), 'products', id);
                        await updateDoc(documentRef, { ...this.formData });
                    }

                    Swal.fire({
                        title: this.$t('success'),
                        text: this.$t('saveSuccessful'),
                        icon: 'success',
                        confirmButtonText: this.$t('ok'),
                        confirmButtonColor: '#0f7438',
                    });

                } catch (error) {
                    Swal.fire({
                        title: this.$t('error'),
                        text: error,
                        icon: 'error',
                        confirmButtonText: this.$t('ok')
                    });
                }

            }
        },
    },

}
</script>

