<template>
    <div class="row mt-3">
        <div class="col-6">
            <h3>{{ $t('customers') }}</h3>
        </div>
        <div class="col-6 text-end">
            <a href="javascript:;" class="btn btn-sm btn-secondary" style="margin-left: 15px;" @click="$exportExcel(colums, items, $t('customers'))"><i class="fa fa-download"></i> {{$t('exportExcel')}}</a>
        </div>
        <div class="col-12">
            <hr/>
        </div>
    </div>
    <div class="row">
        <div class="col-12">

            <CustomDatatable v-if="items.length>0"
            :colums="colums"
            idColumn="id"
            :showDeleteButton="true"
            :showEditButton="true"
            :showCustomActionButton="true"
            :customActionTitle="convertCustomerToEmployee"
            :items="items"
            :editButtonMethod="editData"
            :deleteButtonMethod="deleteData"
            :customActionMethod="convertToEmployee"
            ></CustomDatatable>
        </div>
    </div>
</template>
<script>

import { query, collection, getDocs, doc, deleteDoc, where, getDoc,serverTimestamp } from "firebase/firestore";
import axios from "axios";
import { getFirestore } from "firebase/firestore";
import db from '../firebase/init';
import { getFunctions, httpsCallable } from "firebase/functions";
import Swal from 'sweetalert2';
import CustomDatatable from '@/components/CustomDatatable.vue';


export default {
    components: {
        CustomDatatable
    },
    data(){
        return{
            colums:[
                { key: "displayName", value: this.$t('nameSurname')},
                { key: "phoneNumber", value: this.$t('phoneNumber')},
                { key: "email", value: this.$t('email')},
                { key: "totalOrderCount", value: this.$t('totalOrderCount')},
                { key: "status", value: this.$t('status')},
            ],
            items: [],
            convertCustomerToEmployee: "convertCustomerToEmployee"
        }
    },
    mounted() {
        this.getUsers();
    },
    methods: {
        async getUsers() {

            const querySnap = await getDocs(query(collection(db, 'users'), where('role','==','customer')));
            this.items = querySnap.docs.map((doc) => {
                return {
                    id: doc.id,
                    displayName: doc.data().displayName,
                    storeName: doc.data().storeName,
                    phoneNumber: doc.data().phoneNumber,
                    email: doc.data().email,
                    totalOrderCount: doc.data().totalOrderCount,
                    status: !doc.data().blocked ? this.$t('active') : this.$t('pasive'),
                };
            });
        },
        convertToEmployee(_id){
            Swal.fire({
                title: this.$t('fillTheForm'),
                html: `
                <input type='number' class='form-control' id='customCode' placeholder='${this.$t('customCode')}' />
                <input type='number' class='form-control mt-2' id='taxNumber' placeholder='${this.$t('taxNumber')}' />
                `,
                icon: 'question',
                showCancelButton: true,
                confirmButtonColor: '#0f7438',
                cancelButtonColor: '#6c757d',
                confirmButtonText: this.$t('convert'),
                cancelButtonText: this.$t('cancel'),
                preConfirm: function() {
                  return new Promise((resolve, reject) => {
                      // get your inputs using their placeholder or maybe add IDs to them
                      resolve({
                          taxNumber: document.getElementById("taxNumber").value,
                          customCode: document.getElementById("customCode").value
                      });

                      // maybe also reject() on some condition
                  });
                }
            }).then(async (result) => {
                if (result.isConfirmed) {
                    let res = await this.getCustomers(_id);
                   const functions = getFunctions();
                    const email = res.email;
                    const taxNumber = result.value.taxNumber;
                    const customCode = result.value.customCode;

                  httpsCallable(functions,"changeCustomerToVendor")(
                      {
                        taxNumber: parseFloat(taxNumber),
                        customCode: customCode,
                        userEmail: email
                      }).then(async result => {
                        this.$setLoading(false);
                        if(result.data.success === true){
                          await Swal.fire({
                            title: this.$t('success'),
                            text: result.data.message,
                            icon: 'success',
                            confirmButtonText: this.$t('ok')
                          });
                          await this.getUsers();
                        }else{
                          await Swal.fire({
                            title: this.$t('error'),
                            text: result.data.message,
                            icon: 'error',
                            confirmButtonText: this.$t('ok')
                          });
                        }
                    })
                      .catch(error => {
                        this.$setLoading(false);
                        Swal.fire({
                          title: this.$t('error'),
                          text: result.data.message,
                          icon: 'error',
                          confirmButtonText: this.$t('ok')
                        });
                      });

                    /*axios.post(`https://vega.saeidyarshakiba.com/User/ChangeCustomerToVendor?userEmail=${email}&customCode=${customCode}&taxNumber=${taxNumber}`,null,{
                        headers: {"Access-Control-Allow-Origin": "https://vega.saeidyarshakiba.com"}
                    }).then(response => {
                        if(response.data.toString().includes("Error")){
                            Swal.fire({
                                            title: this.$t('error'),
                                            text: response.data,
                                            icon: 'error',
                                            confirmButtonText: this.$t('ok')
                                        });
                        }
                        else{
                            Swal.fire({
                                            title: this.$t('success'),
                                            text: response.data,
                                            icon: 'success',
                                            confirmButtonText: this.$t('ok')
                                        });
                                        this.getUsers();
                        }
                    }).catch(error => {
                      console.error("There was an error!", error);
                    });*/
                }
            });
        },
        editData(_id){
            this.$router.push({ name: 'customer', params: { id: _id } });
        },
        async getCustomers(id) {
          console.log(id);

            const documentRef = doc(getFirestore(), 'users', id);
            const documentSnapshot = await getDoc(documentRef);

            if (documentSnapshot.exists()) {

                const addressesRef = collection(documentRef, "addresses");
                const addressesSnapshot = await getDocs(addressesRef);

                let addressesData = [];
                addressesSnapshot.forEach((addressDoc) => {
                    addressesData.push(addressDoc.data());
                });

                return {
                    id: id,
                    addresses: addressesData,
                    ...documentSnapshot.data()
                };
            }
            return null;
        },
        deleteData(_id) {
            Swal.fire({
                title: this.$t('areYouSure'),
                text: this.$t('areYouSureDelete'),
                icon: 'question',
                showCancelButton: true,
                confirmButtonColor: '#0f7438',
                cancelButtonColor: '#6c757d',
                confirmButtonText: this.$t('delete'),
                cancelButtonText: this.$t('cancel')
            }).then(async (result) => {
                if (result.isConfirmed) {
                    const functions = getFunctions();
                    httpsCallable(functions,"deleteUser")({userId: _id}).then(async result => {
                        if(result.data.success==true){
                            this.items = [];
                            await this.getUsers();

                            Swal.fire({
                                title: this.$t('success'),
                                text: this.$t('deleteSuccessful'),
                                icon: 'success',
                                confirmButtonText: this.$t('ok'),
                                confirmButtonColor: '#0f7438',
                            });
                        }else{
                            console.log(result.data);
                            Swal.fire({
                                title: this.$t('error'),
                                text: result.data.message,
                                icon: 'error',
                                confirmButtonText: this.$t('ok')
                            });
                        }

                    })
                    .catch(error => {
                        Swal.fire({
                            title: this.$t('error'),
                            text: error,
                            icon: 'error',
                            confirmButtonText: this.$t('ok')
                        });
                    });
                }
            });
        },
    },

}
</script>

