<template>
    <div class="row mt-3">
        <div class="col-6">
            <a href="'#/stores" class="btn btn-sm btn-light"><i class="fa fa-chevron-left"></i> {{ $t('backTo') }}</a>
            <h3>{{ $t('bulkInsertStore') }}</h3>
        </div>
        <div class="col-12">
            <hr/>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6">
            <div class="row">
                <div class="col-12 mb-3">
                    <span class="badge bg-secondary">{{ $t('bulkInsertStoreHelpText') }}</span><br><br>
                    <a :href="baseUrl +'templates/store_template.xlsx'" target="_blank">{{ $t('downloadTemplateFile') }}</a>
                </div>
                <div class="col-12 mb-3">
                    <input type="file" @change="handleFileChange">
                    <button @click="importStore" :disabled="!selectedFile" class="btn btn-sm btn-success">{{ $t('uploadExcelFile') }}</button>
                </div>
            </div>
            
        </div>
        <div class="col-12">
            <hr/>
        </div>
        <div class="col-12">
            <table class="table table-hover" v-if="storeData.length>0">
                <thead>
                    <tr>
                        <th scope="col">{{$t('name')}}</th>
                        <th scope="col">{{$t('description')}}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(p,index) in storeData" :key="index">
                        <td>{{ p.name }}</td>
                        <td>{{ p.processDescription }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>
<script>

import { collection, doc, getDoc, addDoc, setDoc, updateDoc, serverTimestamp, deleteDoc, query, where, getDocs  } from "firebase/firestore";
import { getFirestore } from "firebase/firestore";
import db from '../firebase/init';
import { getFunctions, httpsCallable } from "firebase/functions";
import Swal from 'sweetalert2';
import ExcelJS from 'exceljs';

export default {
    components: {

    },
    data(){
        return {
            formData: {
            },
            storeData:[],
            selectedFile: null,
            baseUrl: process.env.BASE_URL 
        }
    },
    async mounted() {
           
    },  
    methods: {
        handleFileChange(event) {
            this.selectedFile = event.target.files[0];
        },
        async importStore(){
            if(this.selectedFile!=null){

                const reader = new FileReader();

                reader.onload = (e) => {
                    this.$setLoading(true);
                    const data = new Uint8Array(e.target.result);

                    const workbook = new ExcelJS.Workbook();
                    workbook.xlsx.load(data).then(() => {
                        const worksheet = workbook.getWorksheet(1);
                        let rowCount = 0;
                        this.storeData=[];
                        let nowDate = serverTimestamp();
                        const documentRef = collection(getFirestore(), 'stores');
                        worksheet.eachRow({ includeEmpty: false, stopAtFirstEmpty: true }, async (row) => {
                            rowCount++;
                            if(rowCount>1){//Başlıklar için
                                const name = row.getCell('A'); //name.value
                                const taxNumber = row.getCell('B'); //taxNumber.value
                                const advisor = row.getCell('C'); //advisor.value
                                const description = row.getCell('D'); //description.value
                                const email = row.getCell('E'); //email.value
                                const phone = row.getCell('F'); //phone.value
                                const city = row.getCell('G'); //city.value
                                const district = row.getCell('H'); //district.value
                                const address = row.getCell('I'); //address.value

                                if(name.value!=null && name.value!=undefined && taxNumber.value!=null && taxNumber.value!=undefined && advisor.value!=null && advisor.value!=undefined && description.value!=null && description.value!=undefined && email.value!=null && email.value!=undefined && phone.value!=null && phone.value!=undefined && city.value!=null && city.value!=undefined && district.value!=null && district.value!=undefined && address.value!=null && address.value!=undefined){

                                    let emailValue = "";
                                    if (typeof email.value === 'string'){
                                        emailValue = email.value;
                                    }else{
                                        emailValue = email.value.text;
                                    }

                                    try {
                                        let store = {
                                            id: 0,
                                            name: name.value,
                                            taxNumber: parseFloat(taxNumber.value),
                                            advisor: advisor.value,
                                            description: description.value,
                                            email: emailValue,
                                            phone: phone.value,
                                            city: city.value,
                                            district: district.value,
                                            address: address.value,
                                            active: true
                                        };
                                        await addDoc(documentRef, { ...store });

                                        this.storeData.push({
                                            name: name.value,
                                            processDescription: this.$t('saveSuccessful')
                                        });
                                        
                                    } catch (error) {
                                        this.storeData.push({
                                            name: name.value,
                                            processDescription: this.$t('error')+' '+error
                                        });
                                    }

                                }else{
                                    this.storeData.push({
                                        name: name.value,
                                        processDescription: this.$t('missingInformation')
                                    });
                                }
                            }
                            
                        });

                        this.$setLoading(false);
                        
                        Swal.fire({
                            title: this.$t('success'),
                            text: this.$t('saveSuccessful'),
                            icon: 'success',
                            confirmButtonText: this.$t('ok'),
                            confirmButtonColor: '#0f7438',
                        });

                        
                    });
                };


                reader.readAsArrayBuffer(this.selectedFile);
                this.selectedFile = null;
            }
        },
    },

}
</script>

