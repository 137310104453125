<template>
  <router-view/>
</template>

<script>

export default {
  name: 'App'
}
</script>

<style>
.select2-container .select2-selection--single {
  height: 38px !important;
}
.select2-container--default .select2-selection--single {
  border: 1px solid #ced4da !important;
  border-radius: .375rem !important;
}
.select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 36px !important;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
  line-height: 34px !important;
}
</style>
