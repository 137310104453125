import "bootstrap/dist/css/bootstrap.min.css"
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import LoadingHelper from './helper/LoadingHelper';
import i18n from './helper/i18n'
import CKEditor from '@ckeditor/ckeditor5-vue';
import PhoneHelper from './helper/PhoneHelper';
import ExcelExportHelper from "@/helper/ExcelExportHelper";

const app = createApp(App).use(i18n)

// use router
app.use(router)
app.use(i18n)
app.use(LoadingHelper)
app.use(CKEditor)
app.use(PhoneHelper)
app.use(ExcelExportHelper)
app.mount('#app')



import "bootstrap/dist/js/bootstrap.js"
