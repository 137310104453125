<template>
    <div class="row mt-3">
        <div class="col-6">
            <a href="#/vendors" class="btn btn-sm btn-light"><i class="fa fa-chevron-left"></i> {{ $t('backTo') }}</a>
            <h3>{{ $route.params.id==0 ? $t('addNewVendor') : $t('editVendor') }}</h3>
        </div>
        <div class="col-12">
            <hr/>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6">
            <form @submit.prevent="submitForm">
            <div class="mb-3">
                <label for="storeId" class="form-label">{{ $t('store') }}</label>
                <Select2 id="storeId" v-model="formData.taxNumber" :options="storeList">
                    <option v-for="store in stores" :value="store['taxNumber']" :key="store['taxNumber']">{{ store['name']+' ('+store['taxNumber']+')' }}</option>
                </Select2>
            </div>
            <div class="mb-3">
                <label for="displayName" class="form-label">{{ $t('nameSurname') }}</label>
                <input type="text" id="displayName" v-model="formData.displayName" class="form-control" required>
            </div>
            <div class="mb-3">
                <label for="customCode" class="form-label">{{ $t('customCode') }}</label>
                <input type="text" id="customCode" v-model="formData.customCode" class="form-control" required>
            </div>
            <div class="mb-3">
                <label for="email" class="form-label">{{ $t('email') }}</label>
                <input type="email" id="email" v-model="formData.email" class="form-control" required>
            </div>
            <div class="mb-3">
                <label for="phoneNumber" class="form-label">{{ $t('phoneNumber') }}</label>
                <input type="tel" id="phoneNumber" v-model="formData.phoneNumber" v-maska data-maska="+90 (###) ### ## ##" class="form-control" placeholder="+90 (xxx) xxx xx xx" required>
            </div>
            <div class="mb-3">
                <label for="blocked" class="form-check-label">{{ $t('isBlocked') }}</label>
                <input type="checkbox" id="blocked" v-model="formData.blocked" class="form-check-input">
            </div>
            <button type="submit" class="btn btn-success">{{ $t('save') }}</button>
            </form>
        </div>
    </div>
    <div class="row" v-if="$route.params.id!='0'">
        <div class="col-12">
            <hr/>
        </div>
        <div class="col-md-6">
            <form @submit.prevent="submitPointForm">
                <div class="mb-3">
                    <label for="totalPoints" class="form-label">{{ $t('totalPoints')}}</label>
                    <input type="number" class="form-control" id="totalPoints" v-model="points.totalPoints" disabled>
                </div>
                <div class="mb-3">
                    <label for="loadedPoints" class="form-label">{{ $t('loadedPoints')}}</label>
                    <input type="number" class="form-control" id="loadedPoints" v-model="points.loadedPoints" disabled>
                </div>
                <div class="mb-3">
                    <label for="pendingPoints" class="form-label">{{ $t('pendingPoints')}}</label>
                    <input type="number" class="form-control" id="pendingPoints" v-model="points.pendingPoints" disabled>
                </div>
                <div class="mb-3">
                    <label for="transferPoint" class="form-label">{{ $t('transferPoint')}}</label>
                    <input type="number" class="form-control" id="transferPoint" v-model="points.transferPoint">
                </div>
                <button type="submit" class="btn btn-success mb-3">{{ $t('transfer') }}</button>
            </form>
        </div>
    </div>
</template>
<script>
import { vMaska } from "maska";
import { collection, doc, getDoc, addDoc, setDoc, updateDoc, serverTimestamp, deleteDoc, query, where, getDocs  } from "firebase/firestore";
import { getFirestore } from "firebase/firestore";
import db from '../firebase/init';
import { getFunctions, httpsCallable } from "firebase/functions";
import Swal from 'sweetalert2';
import Select2 from 'vue3-select2-component';

export default {
    directives: { maska: vMaska },
    components: { 
        Select2
    },
    data(){
        return {
            formData: {
                id: 0,
                taxNumber: '',
                displayName: '',
                customCode: '',
                email: '',
                phoneNumber: '',
                blocked: false,
                storeId: ''
            },
            points:{
                loadedPoints: 0,
                pendingPoints: 0,
                totalPoints: 0,
                transferPoint: 0
            },
            stores: [],
            storeList: []
        }
    },
    async mounted() {
        const id = this.$route.params.id;
        await this.getStores().then(()=>{
            this.stores.forEach(item=>{
                this.storeList.push({
                    id: item.taxNumber,
                    text: item.name + " (" + item.taxNumber + ")"
                });
            });
        });
        if(id!='0'){
            const vendorData = await this.getVendor(id);
            if (vendorData) {
                this.formData = { ...vendorData };
                this.formData.id = id;
                this.points = { ...vendorData.balances };
                this.points.transferPoint = this.points.pendingPoints;
                this.formData.taxNumber = (this.stores.filter(prop=>prop.id == vendorData.storeId)[0]).taxNumber;
            }
        }
    },  
    methods: {
        async getVendor(id) {
            
            const documentRef = doc(getFirestore(), 'users', id);
            const documentSnapshot = await getDoc(documentRef);

            if (documentSnapshot.exists()) {
                return {
                id: id,
                ...documentSnapshot.data()
                };
            }
            return null;
        },
        async getStores() {
            const q = query(collection(db, 'stores'));

            const querySnap = await getDocs(q);
            this.stores = querySnap.docs.map((doc) => {
                return {
                    id: doc.id,
                    name: doc.data().name,
                    taxNumber: doc.data().taxNumber,
                };
            });

            if(this.stores!=null && this.stores[0]!=null){
                this.formData.taxNumber = this.stores[0].taxNumber;
            }         
            
        },
        async submitForm() {
            const id = this.$route.params.id;
            try {
                this.$setLoading(true);
                const functions = getFunctions();
                const phone = this.$setPhone(this.formData.phoneNumber);
                if (id == '0') {
                    httpsCallable(functions,"createVendorUser")({taxNumber: parseFloat(this.formData.taxNumber), customCode: this.formData.customCode, email: this.formData.email, phoneNumber: phone, displayName: this.formData.displayName}).then(async result => {
                        this.$setLoading(false);
                        if(result.data.success==true){
                            this.formData= {
                                id: 0,
                                taxNumber: '',
                                displayName: '',
                                customCode: '',
                                email: '',
                                phoneNumber: '',
                                blocked: false
                            };
                            Swal.fire({
                                title: this.$t('success'),
                                text: this.$t('vendorAdded'),
                                icon: 'success',
                                confirmButtonText: this.$t('ok'),
                                confirmButtonColor: '#0f7438',
                            });
                        }else{
                            Swal.fire({
                                title: this.$t('error'),
                                text: result.data.message,
                                icon: 'error',
                                confirmButtonText: this.$t('ok')
                            });
                        }
                        
                    })
                    .catch(error => {
                        this.$setLoading(false);
                        Swal.fire({
                            title: this.$t('error'),
                            text: error,
                            icon: 'error',
                            confirmButtonText: this.$t('ok')
                        });
                    });
                    
                    
                } else {
                    httpsCallable(functions,"updateVendorUser")({userId: id, taxNumber: parseFloat(this.formData.taxNumber), customCode: this.formData.customCode, email: this.formData.email, phoneNumber: phone, displayName: this.formData.displayName, blocked: this.formData.blocked}).then(async result => {
                        this.$setLoading(false);
                        if(result.data.success==true){
                            Swal.fire({
                                title: this.$t('success'),
                                text: this.$t('vendorUpdated'),
                                icon: 'success',
                                confirmButtonText: this.$t('ok'),
                                confirmButtonColor: '#0f7438',
                            });
                        }else{
                            Swal.fire({
                                title: this.$t('error'),
                                text: result.data.message,
                                icon: 'error',
                                confirmButtonText: this.$t('ok')
                            });
                        }
                        
                    })
                    .catch(error => {
                        this.$setLoading(false);
                        Swal.fire({
                            title: this.$t('error'),
                            text: error,
                            icon: 'error',
                            confirmButtonText: this.$t('ok')
                        });
                    });
                }
                
            } catch (error) {
                Swal.fire({
                    title: this.$t('error'),
                    text: error,
                    icon: 'error',
                    confirmButtonText: this.$t('ok')
                });
            }
        },
        async submitPointForm(){
            if(this.points.transferPoint<=0 || this.points.transferPoint>this.points.pendingPoints){
                Swal.fire({
                    title: this.$t('warning'),
                    text: this.$t('transferPointInvalid'),
                    icon: 'warning',
                    confirmButtonText: this.$t('ok')
                });
            }else{
                this.$setLoading(true);
                const userId = this.$route.params.id;
                const functions = getFunctions();
                httpsCallable(functions,"transferUserBalance")({userId: userId, amount: this.points.transferPoint}).then(async result => {
                    this.$setLoading(false);
                    if(result.data.success==true){
                        const vendorData = await this.getVendor(userId);
                        if (vendorData) {
                            this.points = { ...vendorData.balances };
                            this.points.transferPoint = this.points.pendingPoints;
                        }
                        Swal.fire({
                            title: this.$t('success'),
                            text: this.$t('transferSuccessful'),
                            icon: 'success',
                            confirmButtonText: this.$t('ok'),
                            confirmButtonColor: '#0f7438',
                        });
                    }else{
                        Swal.fire({
                            title: this.$t('error'),
                            text: result.data.message,
                            icon: 'error',
                            confirmButtonText: this.$t('ok')
                        });
                    }
                    
                })
                .catch(error => {
                    this.$setLoading(false);
                    Swal.fire({
                        title: this.$t('error'),
                        text: error,
                        icon: 'error',
                        confirmButtonText: this.$t('ok')
                    });
                });

            }
        }
    },

}
</script>

