<template>
    <div class="row mt-3">
        <div class="col-6">
            <a href="#/stores" class="btn btn-sm btn-light"><i class="fa fa-chevron-left"></i> {{ $t('backTo') }}</a>
            <h3>{{ $route.params.id==0 ? $t('addNewStore') : $t('editStore') }}</h3>
        </div>
        <div class="col-12">
            <hr/>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6">
            <form @submit.prevent="submitForm">
            <div class="mb-3">
                <label for="name" class="form-label">{{ $t('storeName') }}</label>
                <input type="text" id="name" v-model="formData.name" class="form-control" required>
            </div>
            <div class="mb-3">
                <label for="taxNumber" class="form-label">{{ $t('taxNumber') }}</label>
                <input type="number" id="taxNumber" v-model="formData.taxNumber" class="form-control" required>
            </div>
            <div class="mb-3">
                <label for="advisor" class="form-label">{{ $t('advisor') }}</label>
                <input type="text" id="advisor" v-model="formData.advisor" class="form-control" required>
            </div>
            <div class="mb-3">
                <label for="description" class="form-label">{{ $t('signageName') }}</label>
                <textarea id="description" v-model="formData.description" class="form-control" required></textarea>
            </div>
            <div class="mb-3">
                <label for="email" class="form-label">{{ $t('email') }}</label>
                <input type="email" id="email" v-model="formData.email" class="form-control" required>
            </div>
            <div class="mb-3">
                <label for="phone" class="form-label">{{ $t('phone') }}</label>
                <input type="tel" id="phone" v-model="formData.phone" v-maska data-maska="+90 (###) ### ## ##" class="form-control" placeholder="(xxx) xxx xx xx" required>
            </div>
            <div class="mb-3">
                <label for="city" class="form-label">{{ $t('city') }}</label>
                <input type="text" id="city" v-model="formData.city" class="form-control" required>
            </div>
            <div class="mb-3">
                <label for="district" class="form-label">{{ $t('district') }}</label>
                <input type="text" id="district" v-model="formData.district" class="form-control" required>
            </div>
            <div class="mb-3">
                <label for="address" class="form-label">{{ $t('address') }}</label>
                <input type="text" id="address" v-model="formData.address" class="form-control" required>
            </div>
            <div class="mb-3">
                <label for="active" class="form-check-label">{{ $t('isActive') }}</label>
                <input type="checkbox" id="active" v-model="formData.active" class="form-check-input">
            </div>
            <button type="submit" class="btn btn-success">{{ $t('save') }}</button>
            </form>
        </div>
    </div>
</template>
<script>
import { vMaska } from "maska";
import { collection, doc, getDoc, addDoc, updateDoc, serverTimestamp  } from "firebase/firestore";
import { getFirestore } from "firebase/firestore";
import Swal from 'sweetalert2';

export default {
    directives: { maska: vMaska },
    components: {
        
    },
    data(){
        return {
            formData: {
                id: 0,
                name: '',
                taxNumber: 0,
                advisor: '',
                description: '',
                email: '',
                phone: '',
                city: '',
                district: '',
                address: '',
                active: true
            }
        }
    },
    async mounted() {
        const id = this.$route.params.id;
        if(id!='0'){
            const storeData = await this.getStore(id);

            if (storeData) {
                this.formData = { ...storeData };
                this.formData.id = id;
            }
        }
    },  
    methods: {
        async getStore(id) {
            
            const documentRef = doc(getFirestore(), 'stores', id);
            const documentSnapshot = await getDoc(documentRef);

            if (documentSnapshot.exists()) {
                return {
                id: id,
                ...documentSnapshot.data()
                };
            }
            return null;
        },
        async submitForm() {
            const id = this.$route.params.id;
            try {
                if (id == '0') {
                    const documentRef = collection(getFirestore(), 'stores');
                    await addDoc(documentRef, { ...this.formData, createdDate: serverTimestamp() });
                    this.formData= {
                        id: 0,
                        name: '',
                        taxNumber: 0,
                        advisor: '',
                        description: '',
                        email: '',
                        phone: '',
                        city: '',
                        district: '',
                        address: '',
                        active: true
                    };
                } else {
                    const documentRef = doc(getFirestore(), 'stores', id);
                    await updateDoc(documentRef, { ...this.formData });
                }

                Swal.fire({
                    title: this.$t('success'),
                    text: this.$t('saveSuccessful'),
                    icon: 'success',
                    confirmButtonText: this.$t('ok'),
                    confirmButtonColor: '#0f7438',
                });
                
            } catch (error) {
                Swal.fire({
                    title: this.$t('error'),
                    text: error,
                    icon: 'error',
                    confirmButtonText: this.$t('ok')
                });
            }
        },
    },

}
</script>

