<template>
    <Pie :data="data" :options="options" />
</template>
  
<script>
  import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js'
  import { Pie } from 'vue-chartjs'
  
  ChartJS.register(ArcElement, Tooltip, Legend)
  
  export default {
    name: 'App',
    components: {
      Pie
    },
    props: {
        data: {},
        options: {}
    },
  }
  </script>
  