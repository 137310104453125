<template>
    <div class="row mt-3">
        <div class="col-6">
            <a href="#/pages" class="btn btn-sm btn-light"><i class="fa fa-chevron-left"></i> {{ $t('backTo') }}</a>
            <h3>{{ $route.params.id==0 ? $t('addNewPage') : $t('editPage') }}</h3>
        </div>
        <div class="col-12">
            <hr/>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6">
            <form @submit.prevent="submitForm">
            <div class="mb-3">
                <label for="language" class="form-label">{{ $t('language') }}</label>
                <select class="form-select" id="language" v-model="formData.languageCode">
                    <option value="tr">{{ $t('tr') }}</option>
                    <option value="en">{{ $t('en') }}</option>
                    <option value="de">{{ $t('de') }}</option>
                </select>
            </div>
            <div class="mb-3">
                <label for="name" class="form-label">{{ $t('title') }}</label>
                <input type="text" id="name" v-model="formData.title" class="form-control" required>
            </div>
            <div class="mb-3">
                <label for="pageKey" class="form-label">{{ $t('pageKey') }}</label>
                <input type="text" id="pageKey" v-model="formData.pageKey" class="form-control" :readonly="$route.params.id!=0" required>
            </div>
            <div class="mb-3">
                <label for="content" class="form-label">{{ $t('content') }}</label>
                <ckeditor :editor="editor" v-model="formData.content" :config="editorConfig"></ckeditor>
            </div>
            <button type="submit" class="btn btn-success">{{ $t('save') }}</button>
            </form>
        </div>
    </div>
</template>
<script>
import { collection, doc, getDoc, addDoc, updateDoc, serverTimestamp  } from "firebase/firestore";
import { getFirestore } from "firebase/firestore";
import Swal from 'sweetalert2';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

export default {
    components: {

    },
    data(){
        return {
            formData: {
                id: 0,
                title: '',
                pageKey: '',
                languageCode: 'tr',
                imageUrl: '',
                content: '<p></p>',
                active: true
            },
            editor: ClassicEditor,
            editorConfig: {
                // The configuration of the editor.
            },
        }
    },
    async mounted() {
        const id = this.$route.params.id;
        if(id!='0'){
            const pageData = await this.getPage(id);

            if (pageData) {
                this.formData = { ...pageData };
                this.formData.id = id;
            }
        }
    },
    methods: {
        async getPage(id) {

            const documentRef = doc(getFirestore(), 'pages', id);
            const documentSnapshot = await getDoc(documentRef);

            if (documentSnapshot.exists()) {
                return {
                id: id,
                ...documentSnapshot.data()
                };
            }
            return null;
        },
        async submitForm() {
            const id = this.$route.params.id;
            try {
                if (id == '0') {
                    const documentRef = collection(getFirestore(), 'pages');
                    await addDoc(documentRef, { ...this.formData, createdDate: serverTimestamp() });
                    this.formData= {
                        id: 0,
                        title: '',
                        pageKey: '',
                        languageCode: 'tr',
                        imageUrl: '',
                        content: '<p></p>',
                        active: true
                    };
                } else {
                    const documentRef = doc(getFirestore(), 'pages', id);
                    await updateDoc(documentRef, { ...this.formData });
                }

                Swal.fire({
                    title: this.$t('success'),
                    text: this.$t('saveSuccessful'),
                    icon: 'success',
                    confirmButtonText: this.$t('ok'),
                    confirmButtonColor: '#0f7438',
                });

            } catch (error) {
                Swal.fire({
                    title: this.$t('error'),
                    text: error,
                    icon: 'error',
                    confirmButtonText: this.$t('ok')
                });
            }
        },
    },

}
</script>
