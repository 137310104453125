<template>
    <div class="row mt-3">
        <div class="col-6">
            <h3>{{ $t('giveawayReport') }}</h3>
        </div>
        <div class="col-12">
            <hr/>
        </div>
    </div>
    <div class="row">
        <div class="col-4">
            <div class="mb-3">
                <label for="categoryId" class="form-label">{{ $t('giveaway') }}</label>
                <select class="form-select" id="categoryId" v-model="formData.giveawayId">
                    <option v-for="giveaway in giveaways" :value="giveaway['id']" :key="giveaway['id']">{{ giveaway['title'] }}</option>
                </select>
            </div>
            <br/>
            <button class="btn btn-sm btn-success" @click="exportExcel">{{ $t('exportExcel') }}</button>
        </div>
    </div>
</template>
<script>

import { query, collection, doc, deleteDoc, where, getDocs, getDoc } from "firebase/firestore";
import { getFirestore } from "firebase/firestore";
import db from '../firebase/init';
import Swal from 'sweetalert2';
import ExcelJS from 'exceljs';

export default {
    components: {
        
    },
    data(){
        return{
            formData:{
                giveawayId: 0
            },
            giveaways: [],
            codes: []
        }
    },
    mounted() {
        this.getGiveaways();
    }, 
    methods: {
        async getGiveaways(){
            const querySnap = await getDocs(query(collection(db, 'giveaways')));
            this.giveaways = querySnap.docs.map((doc) => {
                return {
                    id: doc.id,
                    title: doc.data().title
                };
            });
        },
        async exportExcel(){

            if (this.formData.giveawayId == 0 || this.formData.giveawayId == null || this.formData.giveawayId == undefined) {
                Swal.fire({
                    title: this.$t('warning'),
                    text: this.$t('selectGiveawayPlease'),
                    icon: 'warning',
                    confirmButtonText: this.$t('ok')
                });
            }else{
                const documentRef = doc(getFirestore(), 'giveaways', this.formData.giveawayId);
                const documentSnapshot = await getDoc(documentRef);

                if (documentSnapshot.exists()) {
                    const codesRef = collection(documentRef, "codes");
                    const codesSnapshot = await getDocs(codesRef);

                    const workbook = new ExcelJS.Workbook();
                    const worksheet = workbook.addWorksheet('Sheet 1');

                    let columns = [this.$t("giveawayCode"), this.$t("createdDate"), this.$t("isUsed"), this.$t("usedDate"), this.$t("nameSurname"), this.$t("phone"), this.$t("city"), this.$t("district"), this.$t("address")];

                    let rows = [];
                    rows.push(columns);

                    codesSnapshot.forEach((codesDoc) => {

                        let giveawayCode = codesDoc.data().code || null;
                        let createdDate = this.formatDateTurkish(codesDoc.data().createdDate) || null;
                        let isUsed = codesDoc.data().isUsed ? this.$t("used") : '-';
                        let usedDate = this.formatDateTurkish(codesDoc.data().usedDate) || null;
                        let nameSurname = codesDoc.data().displayName || null;
                        let phone = codesDoc.data().phone || null;
                        let city = codesDoc.data().city || null;
                        let district = codesDoc.data().district || null;
                        let address = codesDoc.data().address || null;

                        let row = [giveawayCode, createdDate, isUsed, usedDate, nameSurname, phone, city, district, address];

                        rows.push(row);
                    });

                    if(rows.length>1){
                        worksheet.addRows(rows);

                        // Excel dosyasını indir
                        workbook.xlsx.writeBuffer().then((buffer) => {
                            const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                            const url = window.URL.createObjectURL(blob);
                            const link = document.createElement('a');
                            link.href = url;
                            link.setAttribute('download', 'giveaway-report.xlsx');
                            document.body.appendChild(link);
                            link.click();
                            document.body.removeChild(link);
                        });

                    }else{
                        Swal.fire({
                            title: this.$t('information'),
                            text: this.$t('noGiveawayCodes'),
                            icon: 'info',
                            confirmButtonText: this.$t('ok')
                        });
                    }


                }else{
                    Swal.fire({
                        title: this.$t('information'),
                        text: this.$t('noGiveawayCodes'),
                        icon: 'info',
                        confirmButtonText: this.$t('ok')
                    });
                }    
            }
        },
        formatDateTurkish(timestamp) {
            if(timestamp!=null && timestamp!=undefined){
                const date = timestamp.toDate(); // Firestore Timestamp nesnesini JavaScript Date nesnesine dönüştürün

                const options = { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric' };
                const turkishLocale = 'tr-TR';

                return date.toLocaleDateString(turkishLocale, options);
            }else{
                return null;
            }
        },
        formatDate(timestamp){
            if(timestamp!=null && timestamp!=undefined){
                return timestamp.toDate();
            }else{
                return null;
            }
        }
    },

}
</script>

