<template>
    <div class="row mt-3">
        <div class="col-6">
            <h3>{{ $t('salesReport') }}</h3>
        </div>
        <div class="col-12">
            <hr/>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <Datepicker v-model="formData.startDate" inputFormat="dd.MM.yyyy" language="tr"></Datepicker>
            -
            <Datepicker v-model="formData.endDate" inputFormat="dd.MM.yyyy" language="tr"></Datepicker>
            <br/>
            <button class="btn btn-sm btn-success" @click="exportExcel">{{ $t('exportExcel') }}</button>
        </div>
    </div>
</template>
<script>
import firebase from 'firebase/app';
import {query, collection, doc, deleteDoc, where, getDocs, union, Timestamp} from "firebase/firestore";
import { getFirestore } from "firebase/firestore";
import db from '../firebase/init';
import Swal from 'sweetalert2';
import Datepicker from 'vue3-datepicker';
import ExcelJS from 'exceljs';
import { start } from '@popperjs/core';



export default {
    components: {
        Datepicker
    },
    data(){
        return{
            formData:{
                startDate: "",
                endDate: ""
            },
            orders: []
        }
    },
    mounted() {
        const today = new Date();
        const prevMonth = new Date();
        prevMonth.setMonth(prevMonth.getMonth() - 1);

        this.formData.startDate = prevMonth;
        this.formData.endDate = today;

    },
    methods: {
        async exportExcel(){
            const startDate = new Date(this.formData.startDate);
            const endDate = new Date(this.formData.endDate);
            if (endDate < startDate) {
                Swal.fire({
                    title: this.$t('warning'),
                    text: this.$t('dateRangeWarning'),
                    icon: 'warning',
                    confirmButtonText: this.$t('ok')
                });
            }else{
                const q = query(collection(db, 'orders'),
                    where('createdDate', '>=',  Timestamp.fromDate(new Date(startDate.getFullYear(),startDate.getMonth(),startDate.getDate())))
                    ,where('createdDate', '<=', Timestamp.fromDate(new Date(endDate.getFullYear(),endDate.getMonth(),endDate.getDate())))
                );
                const q2 = query(collection(db, 'orders'),
                    where('vendorCreatedDate', '>=', Timestamp.fromDate(new Date(startDate.getFullYear(),startDate.getMonth(),startDate.getDate()))),
                    where('vendorCreatedDate', '<=',  Timestamp.fromDate(new Date(endDate.getFullYear(),endDate.getMonth(),endDate.getDate())))
                );

                Promise.all([
                    getDocs(q),
                    getDocs(q2)
                ]).then(async (querySnapshots) => {
                    const results1 = querySnapshots[0].docs;
                    const results2 = querySnapshots[1].docs;
                    const combinedResults = [...results1, ...results2];

                    // Sonuçları id'ye göre gruplandırma
                    const groupedOrders = combinedResults.reduce((grouped, doc) => {
                        const data = doc.data();
                        const id = doc.id;
                        if (!grouped[id]) {
                            grouped[id] = {
                                id: id,
                                transactionType: data.transactionType == "sale" ? this.$t("sale") : this.$t("return"),
                                createdDate: this.formatDateTurkish(data.createdDate) || null,
                                customerUserId: data.customerUserId || null,
                                customerName: data.customerName || null,
                                qrCode: data.qrCode || null,
                                productName: data.productName || null,
                                donationName: data.donationTitle || null,
                                vendorStoreId: data.vendorStoreId || null,
                                vendorName: data.vendorName || null,
                                vendorStoreName: data.vendorStoreName || null,
                                vendorCustomCode: data.vendorCustomCode || null,
                                vendorCreatedDate: this.formatDateTurkish(data.vendorCreatedDate) || null,
                                vendorPointAmount: data.vendorPointAmount || null,
                                orderByDate: this.formatDate(data.createdDate) || null
                            };
                        }

                        return grouped;
                    }, {});



                    // Gruplanmış verileri bir diziye çevirme
                    this.orders = Object.values(groupedOrders);

                    this.orders.sort((a, b) => b.orderByDate - a.orderByDate);

                    if(this.orders.length>0){
                        const workbook = new ExcelJS.Workbook();
                        const worksheet = workbook.addWorksheet('Sheet 1');

                        let columns = [this.$t("id"),this.$t("transactionType"), this.$t("createdDate"), this.$t("customerCode"), this.$t("customerName"), this.$t("qrCode"), this.$t("productName"), this.$t("donationName"), this.$t("vendorStoreId"), this.$t("vendorName"), this.$t("vendorStoreName"), this.$t("vendorCustomCode"), this.$t("vendorCreatedDate"), this.$t("vendorPointAmount")];

                        let rows = [];
                        rows.push(columns);

                        for (let i = 0; i < this.orders.length; i++) {
                            let row = [this.orders[i]["id"],this.orders[i]["transactionType"], this.orders[i]["createdDate"], this.orders[i]["customerCode"], this.orders[i]["customerName"], this.orders[i]["qrCode"], this.orders[i]["productName"], this.orders[i]["donationName"], this.orders[i]["vendorStoreId"], this.orders[i]["vendorName"], this.orders[i]["vendorStoreName"], this.orders[i]["vendorCustomCode"], this.orders[i]["vendorCreatedDate"], this.orders[i]["vendorPointAmount"]]

                            rows.push(row);
                        }

                        worksheet.addRows(rows);

                        // Excel dosyasını indir
                        workbook.xlsx.writeBuffer().then((buffer) => {
                            const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                            const url = window.URL.createObjectURL(blob);
                            const link = document.createElement('a');
                            link.href = url;
                            link.setAttribute('download', 'sales-report.xlsx');
                            document.body.appendChild(link);
                            link.click();
                            document.body.removeChild(link);
                        });
                    }else{

                        Swal.fire({
                            title: this.$t('information'),
                            text: this.$t('noSalesBetweenTwoDates'),
                            icon: 'info',
                            confirmButtonText: this.$t('ok')
                        });
                    }

                }).catch((error) => {
                    console.error("Sorgu hatası:", error);
                    Swal.fire({
                        title: this.$t('error'),
                        text: error,
                        icon: 'danger',
                        confirmButtonText: this.$t('ok')
                    });
                });


            }
        },
        formatDateTurkish(timestamp) {
            if(timestamp!=null && timestamp!=undefined){
                const date = timestamp.toDate(); // Firestore Timestamp nesnesini JavaScript Date nesnesine dönüştürün

                const options = { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric' };
                const turkishLocale = 'tr-TR';

                return date.toLocaleDateString(turkishLocale, options);
            }else{
                /*const currentDate = new Date();
                const options = { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric' };
                const turkishLocale = 'tr-TR';

                return currentDate.toLocaleDateString(turkishLocale, options);*/
                return null;
            }
        },
        formatDate(timestamp){
            if(timestamp!=null && timestamp!=undefined){
                return timestamp.toDate();
            }else{
                return null;
            }
        }
    },

}
</script>

