<template>
    <div class="row mt-3">
        <div class="col-6">
            <a href="#/customers" class="btn btn-sm btn-light"><i class="fa fa-chevron-left"></i> {{ $t('backTo') }}</a>
            <h3>{{ $t('editCustomer') }}</h3>
        </div>
        <div class="col-12">
            <hr/>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6">
            <form @submit.prevent="submitForm">
            <div class="mb-3">
                <label for="displayName" class="form-label">{{ $t('nameSurname') }}</label>
                <input type="text" id="displayName" v-model="formData.displayName" class="form-control" required>
            </div>
            <div class="mb-3">
                <label for="email" class="form-label">{{ $t('email') }}</label>
                <input type="email" id="email" v-model="formData.email" class="form-control" required>
            </div>
            <div class="mb-3">
                <label for="phoneNumber" class="form-label">{{ $t('phoneNumber') }}</label>
                <input type="tel" id="phoneNumber" v-model="formData.phoneNumber" v-maska data-maska="+90 (###) ### ## ##" class="form-control" placeholder="+90 (xxx) xxx xx xx" required>
            </div>
            <div class="mb-3">
                <label for="blocked" class="form-check-label">{{ $t('isBlocked') }}</label>
                <input type="checkbox" id="blocked" v-model="formData.blocked" class="form-check-input">
            </div>
            <button type="submit" class="btn btn-success">{{ $t('save') }}</button>
            </form>
        </div>
        <div class="col-md-6" v-if="formData.addresses!=undefined && formData.addresses.length>0">
            {{ $t('addresses') }}
            <div class="row">
                <div class="card col-md-10" style="margin-top: 10px;" v-for="address in formData.addresses" :key="address.name">
                    <div class="card-body">
                        <h5 class="card-title">{{ $t('addressTitle') }}: {{ address.name }}</h5>
                        <h6 class="card-subtitle mb-2 text-muted">{{ address.city }} - {{ address.district }}</h6>
                        <p class="card-text">{{ address.address }}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { vMaska } from "maska";
import { collection, doc, getDoc, addDoc, setDoc, updateDoc, serverTimestamp, deleteDoc, query, where, getDocs  } from "firebase/firestore";
import { getFirestore } from "firebase/firestore";
import db from '../firebase/init';
import { getFunctions, httpsCallable } from "firebase/functions";
import Swal from 'sweetalert2';

export default {
    directives: { maska: vMaska },
    components: {
        
    },
    data(){
        return {
            formData: {
                id: 0,
                storeId: '',
                displayName: '',
                email: '',
                phoneNumber: '',
                blocked: false,
                addresses: []
            },
        }
    },
    async mounted() {
        const id = this.$route.params.id;
        if(id!='0'){
            const customerData = await this.getCustomers(id);

            if (customerData) {
                this.formData = { ...customerData };
                this.formData.id = id;
            }
        }
    },  
    methods: {
        async getCustomers(id) {
            
            const documentRef = doc(getFirestore(), 'users', id);
            const documentSnapshot = await getDoc(documentRef);

            if (documentSnapshot.exists()) {

                const addressesRef = collection(documentRef, "addresses");
                const addressesSnapshot = await getDocs(addressesRef);

                let addressesData = [];
                addressesSnapshot.forEach((addressDoc) => {
                    addressesData.push(addressDoc.data());
                });

                return {
                    id: id,
                    addresses: addressesData,
                    ...documentSnapshot.data()
                };
            }
            return null;
        },
        async submitForm() {
            const id = this.$route.params.id;
            try {
                this.$setLoading(true);
                const functions = getFunctions();
                const phone = this.$setPhone(this.formData.phoneNumber);
                if (id == '0') {
                    Swal.fire({
                        title: this.$t('error'),
                        text: this.$t('error'),
                        icon: 'error',
                        confirmButtonText: this.$t('ok')
                    });
                } else {
                    httpsCallable(functions,"updateUser")({userId: id, storeId: this.formData.storeId, email: this.formData.email, phoneNumber: phone, displayName: this.formData.displayName, blocked: this.formData.blocked}).then(async result => {
                        this.$setLoading(false);
                        if(result.data.success==true){
                            Swal.fire({
                                title: this.$t('success'),
                                text: this.$t('customerUpdated'),
                                icon: 'success',
                                confirmButtonText: this.$t('ok'),
                                confirmButtonColor: '#0f7438',
                            });
                        }else{
                            Swal.fire({
                                title: this.$t('error'),
                                text: result.data.message,
                                icon: 'error',
                                confirmButtonText: this.$t('ok')
                            });
                        }
                        
                    })
                    .catch(error => {
                        this.$setLoading(false);
                        Swal.fire({
                            title: this.$t('error'),
                            text: error,
                            icon: 'error',
                            confirmButtonText: this.$t('ok')
                        });
                    });
                }
                
            } catch (error) {
                Swal.fire({
                    title: this.$t('error'),
                    text: error,
                    icon: 'error',
                    confirmButtonText: this.$t('ok')
                });
            }
        },
    },

}
</script>

