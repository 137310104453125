<template>
    <div class="row mt-3">
        <div class="col-6">
            <a href="#/categories" class="btn btn-sm btn-light"><i class="fa fa-chevron-left"></i> {{ $t('backTo') }}</a>
            <h3>{{ $route.params.id==0 ? $t('addNewCategory') : $t('editCategory') }}</h3>
        </div>
        <div class="col-12">
            <hr/>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6">
            <form @submit.prevent="submitForm">
            <div class="mb-3">
                <label for="language" class="form-label">{{ $t('language') }}</label>
                <select class="form-select" id="language" v-model="formData.languageCode">
                    <option value="tr">{{ $t('tr') }}</option>
                    <option value="en">{{ $t('en') }}</option>
                    <option value="de">{{ $t('de') }}</option>
                </select>
            </div>
            <div class="mb-3">
                <label for="categoryType" class="form-label">{{ $t('categoryType') }}</label>
                <select class="form-select" id="categoryType" v-model="formData.categoryType">
                    <option value="veganic">veganic</option>
                    <option value="collection">collection</option>
                    <option value="hush">hush</option>
                </select>
            </div>
            <div class="mb-3">
                <label for="name" class="form-label">{{ $t('name') }}</label>
                <input type="text" id="name" v-model="formData.name" class="form-control" required>
            </div>
            <div class="mb-3">
                <label for="subtitle" class="form-label">{{ $t('subtitle') }}</label>
                <input type="text" id="subtitle" v-model="formData.subtitle" class="form-control" required>
            </div>
            <div class="mb-3">
                <label for="orderNo" class="form-label">{{ $t('orderNo') }}</label>
                <input type="number" id="orderNo" v-model="formData.orderNo" class="form-control" required>
            </div>
            <div class="mb-3">
                <label for="active" class="form-check-label">{{ $t('isActive') }}</label>
                <input type="checkbox" id="active" v-model="formData.active" class="form-check-input">
            </div>
            <button type="submit" class="btn btn-success">{{ $t('save') }}</button>
            </form>
        </div>
        <div class="col-md-6">
            <div class="row">
                <div class="col-12 mb-3">
                    <label class="form-check-label">{{ $t('categoryCoverPhoto') }}</label><br>
                    <span class="badge bg-secondary">{{ $t('categoryCoverPhotoHelpText') }}</span>
                </div>
                <div class="col-12 mb-3">
                    <input type="file" @change="handleFileChangeCoverPhoto">
                    <button @click="uploadCoverPhoto" :disabled="!selectedCoverPhoto" class="btn btn-sm btn-success">{{ $t('uploadImage') }}</button>
                    <div v-if="formData.coverPhotoUrl!=''">
                        <img :src="formData.coverPhotoUrl" style="width: 50%;">
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12 mb-3">
                    <label class="form-check-label">{{ $t('categoryImage') }}</label><br>
                    <span class="badge bg-secondary">{{ $t('categoryImageHelpText') }}</span>
                </div>
                <div class="col-12 mb-3">
                    <input type="file" @change="handleFileChangeImageUrl">
                    <button @click="uploadImageUrl" :disabled="!selectedImageUrl" class="btn btn-sm btn-success">{{ $t('uploadImage') }}</button>
                    <div v-if="formData.imageUrl!=''">
                        <img :src="formData.imageUrl" style="width: 25%;">
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>
<script>
import { vMaska } from "maska";
import { collection, doc, getDoc, addDoc, updateDoc, serverTimestamp  } from "firebase/firestore";
import { getFirestore } from "firebase/firestore";
import { getStorage, uploadBytes, ref as storageRef, getDownloadURL, deleteObject  } from 'firebase/storage';
import Swal from 'sweetalert2';

export default {
    directives: { maska: vMaska },
    components: {

    },
    data(){
        return {
            formData: {
                id: 0,
                languageCode: 'tr',
                categoryType: 'veganic',
                name: '',
                subtitle: '',
                orderNo: 0,
                coverPhotoUrl: '',
                imageUrl: '',
                active: true
            },
            selectedCoverPhoto: null,
            selectedImageUrl: null
        }
    },
    async mounted() {
        const id = this.$route.params.id;
        if(id!='0'){
            const categoryData = await this.getCategory(id);

            if (categoryData) {
                this.formData = { ...categoryData };
                this.formData.id = id;
            }
        }
    },
    methods: {
        async getCategory(id) {

            const documentRef = doc(getFirestore(), 'categories', id);
            const documentSnapshot = await getDoc(documentRef);

            if (documentSnapshot.exists()) {
                return {
                id: id,
                ...documentSnapshot.data()
                };
            }
            return null;
        },
        handleFileChangeCoverPhoto(event) {
            this.selectedCoverPhoto = event.target.files[0];
        },
        handleFileChangeImageUrl(event) {
            this.selectedImageUrl = event.target.files[0];
        },
        async uploadCoverPhoto(){
            this.$setLoading(true);
            /*if(this.formData.coverPhotoUrl!='')
            {
                const storage = getStorage();
                const imageRef = storageRef(storage, this.formData.coverPhotoUrl);

                deleteObject(imageRef)
                .then(() => {
                    console.log('onceki silindi');
                })
                .catch((error) => {
                    console.error('onceki silinirken bir hata:', error);
                });
            }*/

            const randomNumber = this.generateRandomNumber();
            const storage = getStorage();
            const storageReference = storageRef(storage, 'category_cover_'+randomNumber+'_'+this.selectedCoverPhoto.name);

            try {
                await uploadBytes(storageReference, this.selectedCoverPhoto);
                const url = await getDownloadURL(storageReference);
                this.formData.coverPhotoUrl = url;
                this.selectedCoverPhoto = null;
                this.$setLoading(false);
            } catch (error) {
                this.$setLoading(false);
                Swal.fire({
                    title: this.$t('error'),
                    text: error,
                    icon: 'error',
                    confirmButtonText: this.$t('ok')
                });
            }
        },
        async uploadImageUrl(){
            this.$setLoading(true);
            /*if(this.formData.imageUrl!='')
            {
                const storage = getStorage();
                const imageRef = storageRef(storage, this.formData.imageUrl);

                deleteObject(imageRef)
                .then(() => {
                    console.log('onceki silindi');
                })
                .catch((error) => {
                    console.error('onceki silinirken bir hata:', error);
                });
            }*/

            const randomNumber = this.generateRandomNumber();
            const storage = getStorage();
            const storageReference = storageRef(storage, 'category_'+randomNumber+'_'+this.selectedImageUrl.name);

            try {
                await uploadBytes(storageReference, this.selectedImageUrl);
                const url = await getDownloadURL(storageReference);
                this.formData.imageUrl = url;
                this.selectedImageUrl = null;
                this.$setLoading(false);
            } catch (error) {
                this.$setLoading(false);
                Swal.fire({
                    title: this.$t('error'),
                    text: error,
                    icon: 'error',
                    confirmButtonText: this.$t('ok')
                });
            }
        },
        generateRandomNumber() {
            const min = 100000;
            const max = 999999;
            return Math.floor(Math.random() * (max - min + 1)) + min;
        },
        async submitForm() {
            const id = this.$route.params.id;
            try {
                if (id == '0') {
                    const documentRef = collection(getFirestore(), 'categories');
                    await addDoc(documentRef, { ...this.formData, createdDate: serverTimestamp() });
                    this.formData= {
                        id: 0,
                        languageCode: 'tr',
                        categoryType: 'veganic',
                        name: '',
                        subtitle: '',
                        orderNo: 0,
                        coverPhotoUrl: '',
                        imageUrl: '',
                        active: true
                    };
                } else {
                    const documentRef = doc(getFirestore(), 'categories', id);
                    await updateDoc(documentRef, { ...this.formData });
                }

                Swal.fire({
                    title: this.$t('success'),
                    text: this.$t('saveSuccessful'),
                    icon: 'success',
                    confirmButtonText: this.$t('ok'),
                    confirmButtonColor: '#0f7438',
                });

            } catch (error) {
                Swal.fire({
                    title: this.$t('error'),
                    text: error,
                    icon: 'error',
                    confirmButtonText: this.$t('ok')
                });
            }
        },
    },

}
</script>

