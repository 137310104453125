<template>
    <div class="loading" v-if="loading"><div class="loader"></div> {{$t('loading')}}</div>
    <div class="d-flex" id="wrapper">
            <!-- Sidebar-->
            <div class="border-end" id="sidebar-wrapper" :style="showSidebar ? 'margin-left: auto': 'margin-left: -15rem;'" >
                <div class="sidebar-heading" style="background-color: #287748;"><span style="font-size:18px;font-weight: bold;color: #fff;">Veganic Panel</span></div>
                <div class="list-group list-group-flush">
                    <a class="list-group-item" href="#/dashboard"><i class="fa fa-home"></i> {{ $t('dashboard') }}</a>
                    <hr class="list-group-item-hr"/>
                    <a class="list-group-item" href="#/stores"><i class="fa fa-scissors"></i> {{ $t('stores') }}</a>
                    <a class="list-group-item" href="#/vendors"><i class="fa fa-user"></i> {{ $t('vendors') }}</a>
                    <a class="list-group-item" href="#/customers"><i class="fa fa-user"></i> {{ $t('customers') }}</a>
                    <a class="list-group-item" href="#/transfer-points"><i class="fa fa-star"></i> {{ $t('bulkTransferPoints') }}</a>
                    <hr class="list-group-item-hr"/>
                    <a class="list-group-item" href="#/sales-report"><i class="fa fa-line-chart"></i> {{ $t('salesReport') }}</a>
                    <a class="list-group-item" href="#/sales-report-v2"><i class="fa fa-line-chart"></i> {{ $t('salesReportV2') }}</a>
                    <hr class="list-group-item-hr"/>
                    <a class="list-group-item" href="#/categories"><i class="fa fa-list-alt"></i> {{ $t('categories') }}</a>
                    <a class="list-group-item" href="#/products"><i class="fa fa-box"></i> {{ $t('products') }}</a>
                    <!-- <a class="list-group-item" href="#/product-qr"><i class="fa fa-qrcode"></i> {{ $t('addProductQr') }}</a> -->
                    <hr class="list-group-item-hr"/>
                    <a class="list-group-item" href="#/donations"><i class="fa fa-donate"></i> {{ $t('donations') }}</a>
                    <a class="list-group-item" href="#/giveaways"><i class="fa fa-ticket"></i> {{ $t('giveaways') }}</a>
                    <hr class="list-group-item-hr"/>
                    <a class="list-group-item" href="#/giveaway-report"><i class="fa fa-line-chart"></i> {{ $t('giveawayReport') }}</a>
                    <hr class="list-group-item-hr"/>
                    <a class="list-group-item" href="#/sliders"><i class="fa fa-image"></i> {{ $t('sliders') }}</a>
                    <a class="list-group-item" href="#/push-notification"><i class="fa fa-paper-plane"></i> {{ $t('sendPushNotification') }}</a>
                    <hr class="list-group-item-hr"/>
                    <a class="list-group-item" href="#/pages"><i class="fa fa-file"></i> {{ $t('pages') }}</a>
                    <a class="list-group-item" href="#/product-qr-import"><i class="fa fa-qrcode"></i> {{ $t('importQrCode') }}</a>
                    <a class="list-group-item" href="#/settings"><i class="fa fa-cog"></i> {{ $t('settings') }}</a>
                    <a class="list-group-item" href="#/check-product"><i class="fa fa-list-alt"></i> {{ $t('checkProductQr') }}</a>
                    <a class="list-group-item" href="#/check-giveaways"><i class="fa fa-list-alt"></i> {{ $t('checkGiveAways') }}</a>
                    <hr class="list-group-item-hr" style="margin-bottom: 30px !important;"/>
                </div>
            </div>
            <!-- Page content wrapper-->
            <div id="page-content-wrapper">
                <!-- Top navigation-->
                <nav class="navbar navbar-expand-lg navbar-light bg-light border-bottom">
                    <div class="container-fluid">
                        <div>
                            <button class="btn btn-default" @click="toogleSidebar"><i :class="showSidebar ? 'fa fa-bars' : 'fa fa-chevron-left'"></i> <span style="font-size:18px;font-weight: bold;margin-left: 10px;" v-if="isMobile">Veganic Panel</span></button>
                        </div>
                        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"><span class="navbar-toggler-icon"></span></button>
                        <div class="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul class="navbar-nav ms-auto mt-2 mt-lg-0">
                                <li class="nav-item dropdown">
                                    <a class="nav-link dropdown-toggle" id="navbarDropdown" href="#" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i class="fa fa-user"></i> Veganic Admin</a>
                                    <div class="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdown">
                                        <a class="dropdown-item" href="javascript:;" @click="logout"><i class="fa fa-sign-out"></i> {{ $t('logout') }}</a>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
                <!-- Page content-->
                <div class="container-fluid">
                    <router-view></router-view>
                </div>
            </div>
        </div>

</template>
<script>
import { watch } from 'vue';
import {
  getAuth,
  onAuthStateChanged,
} from "firebase/auth";

export default {
    components: {

    },
    data() {
        return {
            loading: false,
            adminInfo: "",
            isMobile: false,
            showSidebar: true
        }
    },
    mounted(){

        this.loading = this.$getLoading();

        //this.getUserInfo();
        this.isMobile = window.innerWidth <= 768;
        if(this.isMobile){
            this.showSidebar = false;
        }
    },
    created() {
        watch(() => this.$getLoading(),
            (newValue, oldValue) => {
                this.loading = newValue;
            }
        );
    },
    methods: {
        getUserInfo(){
            getAuth().onAuthStateChanged(user => {
                if (user) {
                    this.adminInfo = user.email
                }
            });
        },
        toogleSidebar(){
            if(this.showSidebar){
                this.showSidebar = false;
            }else{
                this.showSidebar = true;
            }
        },
        logout() {
            getAuth()
            .signOut()
            .then(() => {
                console.log('Successfully logged out');
                this.$router.push('/');
            })
            .catch(error => {
                console.log(error.message);
            });
        }
    }
}
</script>
