<template>
    <div class="row mt-3">
        <div class="col-6">
            <h3>{{ $t('bulkTransferPoints') }}</h3>
        </div>
        <div class="col-12">
            <hr/>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6">
            <div class="row">
                <div class="col-12 mb-3">
                    <span class="badge bg-secondary">{{ $t('transferPointsHelpText') }}</span>
                </div>
                <div class="col-12 mb-3">
                    <input type="file" @change="handleFileChange">
                    <button @click="importExcelQr" :disabled="!selectedFile" class="btn btn-sm btn-success">{{ $t('uploadExcelFile') }}</button>
                </div>
            </div>
            
        </div>
        <div class="col-12">
            <hr/>
        </div>
        <div class="col-12">
            <CustomDatatable v-if="customCodeData.length>0"
            :colums="customCodeDataColumn"
            idColumn="customCode"
            :showDeleteButton="false"
            :showEditButton="false"
            :items="customCodeData"
            :editButtonMethod="editData"
            :deleteButtonMethod="deleteData"
            ></CustomDatatable>

            <table class="table table-hover" v-if="customCodeData.length>0">
                <thead>
                    <tr>
                        <th scope="col">{{$t('customCode')}}</th>
                        <th scope="col">{{$t('displayName')}}</th>
                        <th scope="col">{{$t('storeName')}}</th>
                        <th scope="col">{{$t('description')}}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(p,index) in customCodeData" :key="index">
                        <td>{{ p.customCode }}</td>
                        <td>{{ p.displayName }}</td>
                        <td>{{ p.storeName }}</td>
                        <td>{{ p.processDescription }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>
<script>

import { collection, doc, getDoc, addDoc, setDoc, updateDoc, serverTimestamp, deleteDoc, query, where, getDocs  } from "firebase/firestore";
import { getFirestore } from "firebase/firestore";
import db from '../firebase/init';
import { getFunctions, httpsCallable } from "firebase/functions";
import Swal from 'sweetalert2';
//import CustomDatatable from '@/components/CustomDatatable.vue';
import ExcelJS from 'exceljs';

export default {
    components: {
        //CustomDatatable
    },
    data(){
        return {
            formData: {
            },
            customCodeDataColumn: [
                { key: "customCode", value: this.$t('customCode')},
                { key: "displayName", value: this.$t('displayName')},
                { key: "storeName", value: this.$t('storeName')},
                { key: "processDescription", value: this.$t('description')}
            ],
            customCodeData:[],
            selectedFile: null
        }
    },
    async mounted() {
           
    },  
    methods: {
        handleFileChange(event) {
            this.selectedFile = event.target.files[0];
        },
        async importExcelQr(){
            if(this.selectedFile!=null){

                const reader = new FileReader();

                reader.onload = (e) => {
                    this.$setLoading(true);
                    const data = new Uint8Array(e.target.result);

                    const workbook = new ExcelJS.Workbook();
                    workbook.xlsx.load(data).then(() => {
                        const worksheet = workbook.getWorksheet(1);
                        const functions = getFunctions();
                        let rowCount = 0;
                        worksheet.eachRow({ includeEmpty: false, stopAtFirstEmpty: true }, async (row) => {
                            rowCount++;
                            const cell = row.getCell('A');
                            const cellValue = cell.value;
                            if(cellValue!=null && cellValue!="" && cellValue!=undefined){
                                const querySnap = await getDocs(query(collection(db, 'users'), where('customCode','==',""+cellValue)));
                                let user = querySnap.docs.map((doc) => {
                                    return {
                                        id: doc.id,
                                        displayName: doc.data().displayName,
                                        storeName: doc.data().storeName,
                                        customCode: doc.data().customCode,
                                        balances: doc.data().balances,
                                        processDescription: ""
                                    };
                                });

                                if(user!=null && user!=undefined){
                                    
                                    if(user[0].balances.pendingPoints==0 || user[0].balances.pendingPoints == undefined || user[0].balances.pendingPoints == null){
                                        user[0].processDescription = this.$t('noPointPendingTransfer');
                                        this.customCodeData.push(user[0]);
                                    }else{
                                        user[0].processDescription = user[0].balances.pendingPoints+' '+this.$t('xPointTransferred');

                                        httpsCallable(functions,"transferUserBalance")({userId: user[0].id, amount: user[0].balances.pendingPoints}).then(async result => {
                                            if(result.data.success==true){
                                                user[0].processDescription = user[0].balances.pendingPoints+' '+this.$t('xPointTransferred');
                                            }else{
                                                user[0].processDescription = result.data.message;
                                            }
                                            this.customCodeData.push(user[0]);
                                        })
                                        .catch(error => {
                                            user[0].processDescription = error;
                                            this.customCodeData.push(user[0]);
                                        });
                                    }
                                    
                                }
                                
                            }
                        });

                        this.$setLoading(false);
                        

                        
                    });
                };


                reader.readAsArrayBuffer(this.selectedFile);
                this.selectedFile = null;
            }
        },
    },

}
</script>

