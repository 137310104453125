<template>
    <div class="row mt-3">
        <div class="col-6">
            <h3>{{ $t('addProductQr') }}</h3>
        </div>
        <div class="col-12">
            <hr/>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6">
            <form @submit.prevent="submitForm">
            <div class="mb-3">
                <label for="language" class="form-label">{{ $t('language') }}</label>
                <select class="form-select" id="language" v-model="formData.languageCode" @change="getCategories">
                    <option value="tr">{{ $t('tr') }}</option>
                    <option value="en">{{ $t('en') }}</option>
                    <option value="de">{{ $t('de') }}</option>
                </select>
            </div>
            <div class="mb-3">
                <label for="categoryType" class="form-label">{{ $t('categoryType') }}</label>
                <select class="form-select" id="categoryType" v-model="formData.categoryType" @change="getCategories">
                    <option value="veganic">veganic</option>
                    <option value="collection">collection</option>
                    <option value="hush">hush</option>
                </select>
            </div>
            <div class="mb-3">
                <label for="categoryId" class="form-label">{{ $t('category') }}</label>
                <select class="form-select" id="categoryId" v-model="formData.categoryId" @change="getProducts">
                    <option v-for="category in categories" :value="category['id']" :key="category['id']">{{ category['name'] }}</option>
                </select>
            </div>
            <div class="mb-3">
                <label for="productId" class="form-label">{{ $t('product') }}</label>
                <select class="form-select" id="productId" v-model="formData.productId" @change="getProductQr">
                    <option v-for="product in products" :value="product['id']" :key="product['id']">{{ product['name'] }}</option>
                </select>
            </div>
            </form>
        </div>
        <div class="col-md-6">
            <div class="row">
                <div class="col-12 mb-3">
                    <span class="badge bg-secondary">{{ $t('productQrHelpText') }}</span>
                </div>
                <div class="col-12 mb-3">
                    <input type="file" @change="handleFileChange">
                    <button @click="importExcelQr" :disabled="!selectedFile" class="btn btn-sm btn-success">{{ $t('uploadExcelFile') }}</button>
                </div>
            </div>

        </div>
        <div class="col-12">
            <hr/>
        </div>
        <div class="col-12">
            <CustomDatatable v-if="qrData.length>0"
            :colums="qrDataColumn"
            idColumn="id"
            :showDeleteButton="true"
            :showEditButton="false"
            :items="qrData"
            :editButtonMethod="editData"
            :deleteButtonMethod="deleteData"
            ></CustomDatatable>
        </div>
    </div>
</template>
<script>

import { collection, doc, getDoc, addDoc, setDoc, updateDoc, serverTimestamp, deleteDoc, query, where, getDocs  } from "firebase/firestore";
import { getFirestore } from "firebase/firestore";
import db from '../firebase/init';
import { getFunctions, httpsCallable } from "firebase/functions";
import Swal from 'sweetalert2';
import CustomDatatable from '@/components/CustomDatatable.vue';
import ExcelJS from 'exceljs';

export default {
    components: {
        CustomDatatable
    },
    data(){
        return {
            formData: {
                languageCode: 'tr',
                categoryType: 'veganic',
                categoryId: '',
                productId: ''
            },
            categories: [],
            products: [],
            qrDataColumn: [
                { key: "id", value: this.$t('qrCode')},
                { key: "sold", value: this.$t('soldStatus')}
            ],
            qrData:[],
            selectedFile: null
        }
    },
    async mounted() {

        const id = this.$route.params.id;
        await this.getCategories();

    },
    methods: {
        async getCategories() {
            this.qrData = [];
            if(this.formData.languageCode && this.formData.categoryType){
                this.$setLoading(true);
                const q = query(collection(db, 'categories'),
                    where('languageCode', '==', this.formData.languageCode),
                    where('categoryType', '==', this.formData.categoryType)
                );

                const querySnap = await getDocs(q);
                this.categories = querySnap.docs.map((doc) => {
                    return {
                        id: doc.id,
                        name: doc.data().name
                    };
                });

                if(this.categories!=null && this.categories[0]!=null){
                    this.formData.categoryId = this.categories[0].id;
                }

                await this.getProducts();
            }else{
                this.categories = [];
                this.products = [];
            }

        },
        async getProducts() {
            this.qrData = [];
            this.products = [];
            if(this.formData.languageCode && this.formData.categoryType && this.formData.categoryId){
                const q = query(collection(db, 'products'),
                    where('languageCode', '==', this.formData.languageCode),
                    where('categoryType', '==', this.formData.categoryType),
                    where('categoryId', '==', this.formData.categoryId)
                );

                const querySnap = await getDocs(q);
                this.products = querySnap.docs.map((doc) => {
                    return {
                        id: doc.id,
                        name: doc.data().name
                    };
                });

                if(this.products!=null && this.products[0]!=null){
                    this.formData.productId = this.products[0].id;
                }
                if(this.products.length>0){
                    await this.getProductQr();
                }else{
                    this.$setLoading(false);
                }

            }else{
                this.products = [];
            }

        },
        async getProductQr() {

            this.qrData = [];
            const documentRef = doc(getFirestore(), 'products', this.formData.productId);
            const qrCodesCollectionRef = collection(documentRef, 'qrCodes');
            const documentSnapshot = await getDocs(qrCodesCollectionRef);

            if (documentSnapshot.empty) {
                this.qrData = [];
            } else {
                const qrCodes = [];
                documentSnapshot.forEach((doc) => {
                    qrCodes.push({id: doc.id, sold: doc.data().sold ? this.$t('sold') : this.$t('notSold')});
                });
                this.qrData = qrCodes;
            }



            this.$setLoading(false);
        },
        handleFileChange(event) {
            this.selectedFile = event.target.files[0];
        },
        async importExcelQr(){
            if(this.selectedFile!=null){
                const reader = new FileReader();

                reader.onload = (e) => {
                    this.$setLoading(true);
                    const data = new Uint8Array(e.target.result);

                    const workbook = new ExcelJS.Workbook();
                    workbook.xlsx.load(data).then(() => {
                        const worksheet = workbook.getWorksheet(1);

                        const cellValues = [];
                        worksheet.eachRow({ includeEmpty: false, stopAtFirstEmpty: true }, (row, rowNumber) => {
                            const cell = row.getCell('A');
                            const cellValue = cell.value;
                            cellValues.push(cellValue);
                        });

                        console.log(cellValues);

                        if(cellValues.length>0){
                            const functions = getFunctions();
                            httpsCallable(functions,"createProductQrCodes")({productId: this.formData.productId, qrCodes: cellValues}).then(async result => {
                                if(result.data.success==true){
                                    await this.getProductQr();
                                    Swal.fire({
                                        title: this.$t('success'),
                                        text: this.$t('qrDataAddedToProduct'),
                                        icon: 'success',
                                        confirmButtonText: this.$t('ok'),
                                        confirmButtonColor: '#0f7438',
                                    });
                                }else{
                                    this.$setLoading(false);
                                    Swal.fire({
                                        title: this.$t('error'),
                                        text: result.data.message,
                                        icon: 'error',
                                        confirmButtonText: this.$t('ok')
                                    });
                                }

                            })
                            .catch(error => {
                                this.$setLoading(false);
                                Swal.fire({
                                    title: this.$t('error'),
                                    text: error,
                                    icon: 'error',
                                    confirmButtonText: this.$t('ok')
                                });
                            });


                        }else{
                            Swal.fire({
                                title: this.$t('error'),
                                text: this.$t('noDataFoundExcel'),
                                icon: 'error',
                                confirmButtonText: this.$t('ok')
                            });
                        }

                        this.selectedFile = null;
                    });
                };

                reader.readAsArrayBuffer(this.selectedFile);
            }
        },
        generateRandomNumber() {
            const min = 100000;
            const max = 999999;
            return Math.floor(Math.random() * (max - min + 1)) + min;
        },
        deleteData(_id) {
            Swal.fire({
                title: this.$t('areYouSure'),
                text: this.$t('areYouSureDelete'),
                icon: 'question',
                showCancelButton: true,
                confirmButtonColor: '#0f7438',
                cancelButtonColor: '#6c757d',
                confirmButtonText: this.$t('delete'),
                cancelButtonText: this.$t('cancel')
            }).then(async (result) => {
                if (result.isConfirmed) {
                    const documentRef = doc(getFirestore(), 'products', this.formData.productId);
                    const qrCodesCollectionRef = collection(documentRef, 'qrCodes');
                    const qrCodeDocRef = doc(qrCodesCollectionRef, _id);

                    try {
                        await deleteDoc(qrCodeDocRef);
                        Swal.fire({
                            title: this.$t('success'),
                            text: this.$t('deleteSuccessful'),
                            icon: 'success',
                            confirmButtonText: this.$t('ok'),
                            confirmButtonColor: '#0f7438',
                        });
                        await this.getProductQr();
                    } catch (error) {
                        Swal.fire({
                            title: this.$t('error'),
                            text: error,
                            icon: 'error',
                            confirmButtonText: this.$t('ok')
                        });
                    }
                }
            });
        },
    },

}
</script>

