<template>
     <DataTable class="display" width="100%" :options="{dom: 'lftip',buttons:['csv']}">
        <thead>
            <tr>    
                <th v-for="colum in colums" :key="colum.key">{{ colum.value }}</th>
                <th :style="{'width': actionColumSize + 'px'}" v-if="showEditButton===true || showDeleteButton===true || showActionButton === true"></th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="item in items" :key="item[idColumn]">
                <td v-for="(colum,index) in colums" :key="colum.key+index">{{ item[colum.key] }}</td>
                <td class="text-end" style="display: contents;" v-if="showEditButton===true || showDeleteButton===true">
                    <button class="btn btn-sm btn-secondary mt-1" @click="editButtonMethod(item[idColumn])" v-if="showEditButton"><i class="fa fa-edit"></i> {{$t('edit')}}</button>
                    <button class="btn btn-sm btn-danger mt-1" style="margin-left:10px;" @click="deleteButtonMethod(item[idColumn])" v-if="showDeleteButton"><i class="fa fa-remove"></i> {{$t('delete')}}</button>
                    <button class="btn btn-sm btn-warning mt-1" style="margin-left:10px;" @click="customActionMethod(item[idColumn])" v-if="showCustomActionButton"><i class="fa fa-refresh"></i> {{$t(customActionTitle)}}</button>
                </td>
            </tr>
        </tbody>
    </DataTable>
</template>

<script>
import DataTable from 'datatables.net-vue3';
import DataTablesLib from 'datatables.net';
import 'datatables.net-buttons';
import 'datatables.net-buttons/js/buttons.html5';
//import Turkish from '@/translations/trDatatable.json';
DataTable.use(DataTablesLib);
export default {
    components: {
        DataTable
    },
    data(){
        return {
            actionColumSize: 180
        }
    },
    props: {
        colums: [],
        items: [],
        idColumn: String,
        showEditButton: Boolean,
        showDeleteButton: Boolean,
        showCustomActionButton: Boolean,
        customActionTitle: String,
        editButtonMethod: Function,
        deleteButtonMethod: Function,
        customActionMethod: Function
    },
    beforeMount(){
        if(this.showCustomActionButton){
            this.actionColumSize = 300;
        }
    },
    mounted(){
        
    }
  // ...
}
</script>
<style>
@import 'datatables.net-dt';
</style>