<template>
    <div class="row mt-3">
        <div class="col-6">
            <a href="#/giveaways" class="btn btn-sm btn-light"><i class="fa fa-chevron-left"></i> {{ $t('backTo') }}</a>
            <h3>{{ $route.params.id==0 ? $t('addNewGiveaway') : $t('editGiveaway') }}</h3>
        </div>
        <div class="col-6 text-end" v-if="$route.params.id!=0" style="padding-top: 30px;padding-right: 20px;">
            <a :href="'#/giveaway-code/'+$route.params.id" class="btn btn-sm btn-success"><i class="fa fa-upload"></i> {{$t('uploadGiveawayCodes')}}</a>
        </div>
        <div class="col-12">
            <hr/>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6">
            <form @submit.prevent="submitForm">
            <div class="mb-3">
                <label for="language" class="form-label">{{ $t('language') }}</label>
                <select class="form-select" id="language" v-model="formData.languageCode">
                    <option value="tr">{{ $t('tr') }}</option>
                    <option value="en">{{ $t('en') }}</option>
                    <option value="de">{{ $t('de') }}</option>
                </select>
            </div>
            <div class="mb-3">
                <label for="title" class="form-label">{{ $t('title') }}</label>
                <input type="text" id="title" v-model="formData.title" class="form-control" required>
            </div>
            <div class="mb-3">
                <label for="description" class="form-label">{{ $t('description') }}</label>
                <textarea id="description" v-model="formData.description" class="form-control" required></textarea>
            </div>
            <div class="mb-3">
                <label for="resultContent" class="form-label">{{ $t('giveawayResultContent') }}</label>
                <ckeditor :editor="editor" v-model="formData.resultContent" :config="editorConfig"></ckeditor>
            </div>
            <div class="mb-3">
                <label for="active" class="form-check-label">{{ $t('isActive') }}</label>
                <input type="checkbox" id="active" v-model="formData.active" class="form-check-input">
            </div>
            <button type="submit" class="btn btn-success">{{ $t('save') }}</button>
            </form>
        </div>
        <div class="col-md-6">
            <div class="row">
                <div class="col-12 mb-3">
                    <span class="badge bg-secondary">{{ $t('giveawayImageHelpText') }}</span>
                </div>
                <div class="col-12 mb-3">
                    <input type="file" @change="handleFileChange">
                    <button @click="uploadImage" :disabled="!selectedImage" class="btn btn-sm btn-success">{{ $t('uploadImage') }}</button>
                    <div v-if="formData.imageUrl!=''">
                        <img :src="formData.imageUrl" style="width: 50%;">
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>
<script>
import { collection, doc, getDoc, addDoc, setDoc, updateDoc, serverTimestamp, query, where, getDocs  } from "firebase/firestore";
import { getFirestore } from "firebase/firestore";
import { getStorage, uploadBytes, ref as storageRef, getDownloadURL, deleteObject  } from 'firebase/storage';
import Swal from 'sweetalert2';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

export default {
    components: {

    },
    data(){
        return {
            formData: {
                id: 0,
                languageCode: 'tr',
                title: '',
                description: '',
                imageUrl: '',
                resultContent: '',
                active: true
            },
            selectedImage: null,
            editor: ClassicEditor,
            editorConfig: {
                // The configuration of the editor.
            },
        }
    },
    async mounted() {
        const id = this.$route.params.id;
        if(id!='0'){
            const giveawayData = await this.getGiveaway(id);

            if (giveawayData) {
                this.formData = { ...giveawayData };
                this.formData.id = id;
            }
        }
    },
    methods: {
        async getGiveaway(id) {

            const documentRef = doc(getFirestore(), 'giveaways', id);
            const documentSnapshot = await getDoc(documentRef);

            if (documentSnapshot.exists()) {
                return {
                id: id,
                ...documentSnapshot.data()
                };
            }
            return null;
        },
        handleFileChange(event) {
            this.selectedImage = event.target.files[0];
        },
        async uploadImage(){
            this.$setLoading(true);
            if(this.formData.imageUrl!='')
            {
                const storage = getStorage();
                const imageRef = storageRef(storage, this.formData.imageUrl);

                deleteObject(imageRef)
                .then(() => {
                    console.log('onceki silindi');
                })
                .catch((error) => {
                    console.error('onceki silinirken bir hata:', error);
                });
            }

            const randomNumber = this.generateRandomNumber();
            const storage = getStorage();
            const storageReference = storageRef(storage, 'giveaway_'+randomNumber+'_'+this.selectedImage.name);

            try {
                await uploadBytes(storageReference, this.selectedImage);
                const url = await getDownloadURL(storageReference);
                this.formData.imageUrl = url;
                this.selectedImage = null;
                this.$setLoading(false);
            } catch (error) {
                this.$setLoading(false);
                Swal.fire({
                    title: this.$t('error'),
                    text: error,
                    icon: 'error',
                    confirmButtonText: this.$t('ok')
                });
            }
        },
        generateRandomNumber() {
            const min = 100000;
            const max = 999999;
            return Math.floor(Math.random() * (max - min + 1)) + min;
        },
        async submitForm() {
            const id = this.$route.params.id;

            if(this.formData.imageUrl == '' || this.formData.imageUrl == null || this.formData.imageUrl == undefined){
                Swal.fire({
                    title: this.$t('warning'),
                    text: this.$t('pleaseUploadImage'),
                    icon: 'warning',
                    confirmButtonText: this.$t('ok')
                });
            }else{

                try {
                    if (id == '0') {
                        const documentRef = collection(getFirestore(), 'giveaways');
                        await addDoc(documentRef, { ...this.formData, createdDate: serverTimestamp() });
                        this.formData= {
                            id: 0,
                            languageCode: 'tr',
                            title: '',
                            description: '',
                            imageUrl: '',
                            resultContent: '',
                            active: true
                        };
                    } else {
                        const documentRef = doc(getFirestore(), 'giveaways', id);
                        await updateDoc(documentRef, { ...this.formData });
                    }

                    Swal.fire({
                        title: this.$t('success'),
                        text: this.$t('saveSuccessful'),
                        icon: 'success',
                        confirmButtonText: this.$t('ok'),
                        confirmButtonColor: '#0f7438',
                    });

                } catch (error) {
                    Swal.fire({
                        title: this.$t('error'),
                        text: error,
                        icon: 'error',
                        confirmButtonText: this.$t('ok')
                    });
                }
            }

        },
    },

}
</script>

