<template>
    <div class="row mt-3">
        <div class="col-6">
            <h3>{{ $t('importQrCode') }}</h3>
        </div>
        <div class="col-12">
            <hr/>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <!-- <Datepicker v-model="formData.startDate" inputFormat="dd.MM.yyyy" language="tr"></Datepicker>
            -
            <Datepicker v-model="formData.endDate" inputFormat="dd.MM.yyyy" language="tr"></Datepicker>
            <br/> -->
            <button class="btn btn-sm btn-success" @click="startTransfer" :disabled="transfering">{{ $t('startTransfer') }}</button><br><br>

            <p :style="(apiResultStatus ? 'color: #198754;': 'color: #ff0000;')" v-html="apiResultText"></p>
            <p :style="(resultStatus ? 'color: #198754;': 'color: #ff0000;')" v-html="resultText"></p>
            
            <!-- <button class="btn btn-sm btn-danger" @click="removeTodayAddedQrCodes" >Remove Today Added QR</button> -->

        </div>
    </div>
</template>
<script>

import { query, collection, doc, deleteDoc, where, getDocs, getDoc, union, setDoc, serverTimestamp, addDoc } from "firebase/firestore";
import { getFirestore } from "firebase/firestore";
import db from '../firebase/init';
import Datepicker from 'vue3-datepicker';
import Swal from 'sweetalert2';
import jsonData from '../../public/transfer/20-11-2023-qr-data.json';

export default {
    components: {
        Datepicker
    },
    data(){
        return{
            formData:{
                startDate: "",
                endDate: ""
            },
            transfering: false,
            products: jsonData,
            resultStatus: false,
            resultText: "",
            apiResultStatus: false,
            apiResultText: ""
        }
    },
    mounted() {
        const today = new Date();
        const prevMonth = new Date();
        prevMonth.setMonth(prevMonth.getMonth() - 1);

        this.formData.startDate = prevMonth;
        this.formData.endDate = today;
    }, 
    methods: {

        async getData(){
            const startDate = this.formatDate(this.formData.startDate);
            const endDate = this.formatDate(this.formData.endDate);
            const startStr = new Date(this.formData.startDate);
            const endStr = new Date(this.formData.endDate);

            this.transfering = true;

            if((startDate == null || endDate == null) || startStr > endStr){
                Swal.fire({
                    title: this.$t('warning'),
                    text: this.$t('dateRangeWarning'),
                    icon: 'warning',
                    confirmButtonText: this.$t('ok')
                });
            }else{

                const result = await fetch('http://46.221.63.202:1538/api/orders/ext?startDate='+startDate+'&endDate='+endDate, {
                    method: 'GET',
                    headers: { "x-api-key": "B37CA5EB-2524-484B-87B2-B5123D60599E", 'Accept': 'application/json' }
                });
                console.log(result);
                this.transfering = false;

                if(!result.ok || !result || result.json() == null){
                    
                    Swal.fire({
                        title: this.$t('error'),
                        text: "Data stil api problem!",
                        icon: 'danger',
                        confirmButtonText: this.$t('ok')
                    });
                    return;
                }else{
                    
                    this.products = data;
                    console.log("ürünler geldi");
                    console.log(this.products[0]);
                }

            }

        },

        async startTransfer(){
            
            let currentTime = serverTimestamp();
            if(this.products!=null && this.products.length>0){
                this.transfering = true;
                this.resultText += "Ürün Sayısı: "+this.products.length+"<br>";

                for (const value of this.products){
                    this.resultText+=value.urunno.toString()+" nolu ürün: ";
                    const productRef = doc(getFirestore(), 'products', value.urunno.toString());
                    const productSnapshot = await getDoc(productRef);
                    if (productSnapshot.exists()) {

                        const integrationDataRef = collection(productRef, 'integrationData');
                        const integrationDataSnapshot = await getDocs(integrationDataRef);

                        let oldData = null;
                        if(integrationDataSnapshot.size > 0){
                            oldData = integrationDataSnapshot.docs.map((doc) => {
                                const data = doc.data();
                                data.id = doc.id; 
                                return data;
                            });
                        }

                        const newData = {
                            id: value.id, uretimtarihi: value.uretimtarihi, urunno: value.urunno, urunadi: value.urunadi, sonkullanimtarihi: value.sonkullanimtarihi
                        };
                        
                        try{
                            const mergeData = { ...oldData, ...newData };
                            await addDoc(integrationDataRef, mergeData);
                        }catch (error){
                            //
                        }
                        

                        if(value.karekodlar!=undefined && value.karekodlar!=null && value.karekodlar.length>0){
                            
                            this.resultText+="QR Kod Sayısı: "+value.karekodlar.length+". ";

                            for (const qrValue of value.karekodlar){
                                const qrCodeRef = doc(productRef, 'qrCodes', qrValue.karekod.toString());
                                const qrCodeSnapshot = await getDoc(qrCodeRef);

                                if (!qrCodeSnapshot.exists()) {
                                    const newQrData = {
                                        sold: false,
                                        vendorUserId: null,
                                        createdDate: currentTime,
                                        gtin: qrValue.gtin.toString(),
                                        sn: qrValue.sn.toString(),
                                        pn: qrValue.pn.toString(),
                                        skt: qrValue.skt.toString(),
                                    };
                                    
                                    try{
                                        const qrCodeSetDataRef = collection(productRef, 'qrCodes');
                                        await setDoc(doc(qrCodeSetDataRef, qrValue.karekod.toString()), newQrData);
                                        /*
                                        await addDoc(qrCodeSetDataRef, newQrData);*/
                                        console.log('yeni eklendi',qrValue.karekod.toString());
                                    }catch (error){
                                        //
                                    }
                                    
                                }else{
                                    console.log('var',qrValue.karekod.toString());
                                }
                            };

                            this.resultText+=" Kaydedildi.<br>";

                        }else{
                            this.resultText+=" için dosyada karekodu bulunmuyor!<br>";
                        }

                    }else{
                        this.resultText+=" daha önceden eklenmemiş!<br>";
                    }
                };

                this.transfering = false;
                this.resultStatus = true;

                this.resultText+="<br><b>KAYIT BAŞARILI</b>";
                //this.resultText = message;
            }else{
                this.resultStatus = false;
                this.resultText = "Ürün bulunamadı";
            }
        },

        async removeTodayAddedQrCodes(){

            const today = new Date();

                // Ürünleri al
                const productsSnapshot = await getDocs(query(collection(db, 'products')));

                let count = 0;
                let deltedCount = 0;
                if(productsSnapshot!= undefined && productsSnapshot.docs!=null){

                    for(const productDoc of productsSnapshot.docs){
                        //console.log(productDoc.id);
                        const documentRef = doc(getFirestore(), 'products', productDoc.id);
                        const qrCodesRef = collection(documentRef, 'qrCodes');
                        const qrCodesSnapshot = await getDocs(qrCodesRef);

                        if(qrCodesSnapshot!=undefined && qrCodesSnapshot.docs!=null){
                            count+=qrCodesSnapshot.docs.length;
                            // qrCodes koleksiyonunu kontrol et ve sil
                            for(const qrCodeDoc of qrCodesSnapshot.docs){
                                try{
                                    //console.log(qrCodeDoc.id)
                                    const createdDate = qrCodeDoc.data().createdDate.toDate()
                                    //console.log(createdDate);
                                    if (createdDate.toDateString() === today.toDateString()) {
                                        deltedCount++;
                                        console.log(qrCodeDoc.id,"silindi ("+deltedCount+")");
                                        const qrCodeDocRef = doc(qrCodesRef, qrCodeDoc.id);
                                        await deleteDoc(qrCodeDocRef);
                                    }
                                }catch(error){
                                    console.log(qrCodeDoc.id,"hata oluştu");
                                }
                                
                            };
                        }
                        

                    };
                }
                console.log('Toplam sayı:',count);
                console.log('İşlem tamamlandı.');
        },

        formatDate(date){
            if(date != "" && date!=null){
                const year = date.getFullYear();
                const month = (date.getMonth() + 1);
                const day = date.getDate();

                const formattedYear = year.toString();
                const formattedMonth = month < 10 ? "0" + month : month.toString();
                const formattedDay = day < 10 ? "0" + day : day.toString();

                return `${formattedYear}-${formattedMonth}-${formattedDay}`;
            }
            return null;
        }

    }

}
</script>

