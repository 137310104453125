import { createRouter, createWebHashHistory } from 'vue-router'
import {
  getAuth,
  onAuthStateChanged
} from "firebase/auth";

import LoginPage from '../views/LoginPage'
import ForgetPassword from '../views/ForgetPassword'

import Layout from '../views/Layout'
import Dashboard from '../views/Dashboard'

import StoreList from '../views/StoreList'
import StoreAdd from '../views/StoreAdd'
import StoreBulkInsert from '../views/StoreBulkInsert'

import VendorList from '../views/VendorList'
import VendorAdd from '../views/VendorAdd'
import VendorBulkInsert from '../views/VendorBulkInsert'

import CustomerList from '../views/CustomerList'
import CustomerAdd from '../views/CustomerAdd'

import TransferPoints from '../views/TransferPoints'

import SalesReport from '../views/SalesReport'
import SalesReportExt from '../views/SalesReportExt'

import CategoriesList from '../views/CategoryList'
import CategoryAdd from '../views/CategoryAdd'

import ProductList from '../views/ProductList'
import ProductAdd from '../views/ProductAdd'
import ProductQrAdd from '../views/ProductQrAdd'
import ProductQrImport from '../views/ProductQrImport'

import DonationList from '../views/DonationList'
import DonationAdd from '../views/DonationAdd'

import GiveawayList from '../views/GiveawayList'
import GiveawayAdd from '../views/GiveawayAdd'
import GiveawayCodes from '../views/GiveawayCodes'
import GiveawayReport from '../views/GiveawayReport'

import PageList from '../views/PageList'
import PageAdd from '../views/PageAdd'

import SliderList from '../views/SliderList'
import SliderAdd from '../views/SliderAdd'

import PushNotification from '../views/PushNotification'
import Settings from '../views/Settings'
import CheckProductQr from "@/views/CheckProductQr.vue";
import CheckGiveAways from "@/views/CheckGiveAways.vue";



const router = createRouter({
  history: createWebHashHistory(),
  routes: [
    {
      path: '/',
      component: LoginPage
    },
    {
      path: '/forget-password',
      component: ForgetPassword
    },
    {
      path: '/layout',
      component: Layout,
      children: [
         {
          name: 'dashboard',
           path: '/dashboard',
           component: Dashboard,
         },
         {
          name: 'stores',
          path: '/stores',
          component: StoreList,
         },
         {
          name: 'store',
          path: '/store/:id',
          component: StoreAdd,
         },
         {
          name: 'store-bulk-insert',
          path: '/store-bulk-insert',
          component: StoreBulkInsert,
         },
         {
          name: 'vendors',
          path: '/vendors',
          component: VendorList,
         },
         {
          name: 'vendor',
          path: '/vendor/:id',
          component: VendorAdd,
         },
         {
          name: 'vendor-bulk-insert',
          path: '/vendor-bulk-insert',
          component: VendorBulkInsert,
         },
         {
          name: 'customers',
          path: '/customers',
          component: CustomerList,
         },
         {
          name: 'customer',
          path: '/customer/:id',
          component: CustomerAdd,
         },
         {
          name: 'transfer-points',
          path: '/transfer-points',
          component: TransferPoints,
         },
         {
          name: 'sales-report',
          path: '/sales-report',
          component: SalesReport,
         },
         {
          name: 'sales-report-v2',
          path: '/sales-report-v2',
          component: SalesReportExt,
         },
         {
          name: 'categories',
          path: '/categories',
          component: CategoriesList,
         },
         {
          name: 'category',
          path: '/category/:id',
          component: CategoryAdd,
         },
         {
          name: 'products',
          path: '/products',
          component: ProductList,
         },
         {
          name: 'product',
          path: '/product/:id',
          component: ProductAdd,
         },
         {
          name: 'product-qr',
          path: '/product-qr',
          component: ProductQrAdd,
         },
         {
          name: 'product-qr-import',
          path: '/product-qr-import',
          component: ProductQrImport,
         },
         {
          name: 'donations',
          path: '/donations',
          component: DonationList,
         },
         {
          name: 'donation',
          path: '/donation/:id',
          component: DonationAdd,
         },
         {
          name: 'giveaways',
          path: '/giveaways',
          component: GiveawayList,
         },
         {
          name: 'giveaway',
          path: '/giveaway/:id',
          component: GiveawayAdd,
         },
         {
          name: 'giveaway-code',
          path: '/giveaway-code/:id',
          component: GiveawayCodes,
         },
         {
          name: 'giveaway-report',
          path: '/giveaway-report',
          component: GiveawayReport,
         },
         {
          name: 'pages',
          path: '/pages',
          component: PageList,
         },
         {
          name: 'page',
          path: '/page/:id',
          component: PageAdd,
         },
         {
          name: 'sliders',
          path: '/sliders',
          component: SliderList,
         },
         {
          name: 'slider',
          path: '/slider/:id',
          component: SliderAdd,
         },
         {
          name: 'push-notification',
          path: '/push-notification',
          component: PushNotification,
         },
         {
          name: 'settings',
          path: '/settings',
          component: Settings,
         },
         {
          name: 'checkProductQr',
          path: '/check-product',
          component: CheckProductQr,
         },
         {
          name: 'checkGiveAways',
          path: '/check-giveaways',
          component: CheckGiveAways,
         }
       ],
    }
  ]
});

router.beforeEach((to, from, next)=>{
  const auth = getAuth();
  if (to.path === "/forget-password"){
    next(true);
  }else if (to.path === "/"){
    onAuthStateChanged(auth, (user) => {
      if (user) {
        localStorage.setItem('uid', user.uid);
        next("/dashboard");
      }else{
        next(true);
      }
    });
  }else{
    onAuthStateChanged(auth, (user) => {
      if (user) {
        next(true);
      }else{
        next("/");
      }
    });
  }
});

export default router
